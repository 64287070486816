import React from 'react';
import {t} from 'ttag';
import SidebarLink from './layout/main-layout/SidebarLink';
import { ROLES } from "./utils/user/user-utils";
import {AuthorizationGuard} from "./guards";
import SidebarDropDownLink from "./layout/main-layout/SidebarDropDownLink";
import {useStore} from "./store/valtio";
import {
  ConnectingAirports,
  Dashboard,
  Flight,
  HowToReg,
  Settings,
  Receipt,
  SpeakerNotesOutlined, RequestQuote
} from '@mui/icons-material';

export const SidebarMenu = () => {
  const {app} = useStore();

  return (
  <>
      <SidebarLink path={'/'} text={t`Dashboard`} icon={<Dashboard/>} exact />

      <AuthorizationGuard userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}>
        <SidebarLink path={'/refunds'} text={t`Refunds`} icon={<RequestQuote/>} />
      </AuthorizationGuard>

      {/*<AuthorizationGuard userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}>
        <SidebarLink path={'/receipt-request'} text={t`Receipt Request`} icon={<Receipt/>} />
      </AuthorizationGuard>*/}

      <AuthorizationGuard userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}>
        <SidebarLink path={'/claims'} text={t`Check Claims`} icon={<SpeakerNotesOutlined/>} />
      </AuthorizationGuard>

      <AuthorizationGuard userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}>
        <SidebarLink path={'/transport-facilities'} text={t`Industry Discount`} icon={<Flight style={{rotate: '90deg'}}/>} />
      </AuthorizationGuard>

      <AuthorizationGuard userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_RESIDENT_VALIDATOR, ROLES.CCS_AGENT, ROLES.CCS_SUPERVISOR]}>
        <SidebarLink path={'/resident-validator'} text={t`Resident Validator`} icon={<HowToReg/>} exact/>
      </AuthorizationGuard>

      <AuthorizationGuard userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_RESIDENT_VALIDATOR, ROLES.CCS_AGENT, ROLES.CCS_SUPERVISOR]}>
          <SidebarDropDownLink
              path={'/links'}
              text={app.sideBarOpen ? t`Cash & Miles` : 'C&M'}
              icon={<ConnectingAirports style={{fontSize: '30px'}}/>}
              subLinks={[
                  {path: 'https://cashmiles.sata.pt', text: `Cash & Miles APP`, externalLink: true},
                  {path: '/cash_and_miles.xlsx', text: `Cash & Miles Ratio`, externalLink: true},
              ]}
          />
      </AuthorizationGuard>
      <AuthorizationGuard userHasSomeOf={[ROLES.CCS_ADMIN]}>
        <SidebarLink path={'/settings'} text={t`Settings`} icon={<Settings/>}/>
      </AuthorizationGuard>

  </>
)};

export default SidebarMenu;
