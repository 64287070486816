import {ReactElement, ReactNode, useEffect} from 'react';
import {Theme, useMediaQuery} from '@mui/material';
import React from 'react';
import {actions, useStore} from "../store/valtio";

type AppInitProviderProps = {
  children?: string | null | ReactElement | ReactNode
}

export const AppInitProvider = ({children}: AppInitProviderProps) => {
  useStore();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    actions.app.init();
  }, []);

  useEffect(() => {
    actions.app.setSideBarOpen(isDesktop);
  }, [isDesktop]);


  return (
    <>
      {children}
    </>
  );
};

export default AppInitProvider;
