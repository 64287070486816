// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import {Grid, TextField} from "@mui/material";

const RoleForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Name`}
          name='name'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('name', e.target.value)}
          value={formik.values?.name || ''}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Description`}
          name='description'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('description', e.target.value)}
          value={formik.values?.description || ''}
          error={Boolean(formik.errors.description)}
          helperText={formik.errors.description || ''}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

RoleForm.propTypes = {
  formik: PropTypes.object
};

RoleForm.defaultProps = {
  formik: {}
};

export default RoleForm;
