import {AUDIENCE_TRANSPORT} from "../../config/settings";

export const ROLES = {
  TRANSPORT_ADMIN: AUDIENCE_TRANSPORT + '_Administrator',
  TRANSPORT_EMPLOYEE: AUDIENCE_TRANSPORT + '_Employee',
  CCS_ADMIN: 'Admin',
  CCS_EMPLOYEE: 'Employee',
  CCS_AGENT: 'Agent',
  CCS_SUPERVISOR: 'Supervisor',
  CCS_RESIDENT_VALIDATOR: 'Resident.validator',
};

export const hasSomeOf = (user: any, roles: string[]|undefined = []) => {
  return !roles.length ||
    roles.some((rName: any) =>
      user?.roles.some(
        (uRole: { name: string; }) => uRole?.name?.toLowerCase() === rName?.toLowerCase()
      )
    );
}

export const hasEveryOf = (user: any, roles: string[]|undefined = []) =>
    !roles.length ||
    roles.every((rName: any) =>
        user?.roles.some(
            (uRole: { name: string; }) => uRole?.name?.toLowerCase() === rName?.toLowerCase()
        )
    );
