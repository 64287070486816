// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import RoleForm from './RoleForm';
import { roleValidationSchema } from './helpers';
import { useCreateRoleMutation } from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {enqueueSnackbar} from "notistack";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

const AddRoleDialog = ({ role, open, onClose }) => {
  const [addRole, { data, error, isLoading }] = useCreateRoleMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: role,
    validationSchema: roleValidationSchema,
    onSubmit: (values) => addRole(values).then((r) => {
      if (!r.error) {
        enqueueSnackbar(t`The role` + ` ${r?.data?.name} ` + t`was successfully added!`, {variant: 'success'});
        onClose();
      }})
  });

  return (
    <>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Add Role`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <RoleForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={formik.handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddRoleDialog.propTypes = {
  role: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

AddRoleDialog.defaultProps = {
  open: true
};

export default AddRoleDialog;
