import {useSnapshot} from 'valtio';
import initialState from './initialState';
import {devtools} from 'valtio/utils';
import {ENABLE_REDUX_DEV_TOOLS} from "../../config/settings";
import {createStore} from './store-utils';

export const store = createStore(initialState);
export const useStore = () => useSnapshot(store);

if (ENABLE_REDUX_DEV_TOOLS) {
  devtools(store, 'STORE');
}

export default store;
