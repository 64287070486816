// @ts-nocheck
import React from 'react';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import {t} from 'ttag';
import {useWatch} from 'react-hook-form';
import Amount from './Amount';
import {DataGrid} from '@mui/x-data-grid';
import table from '../../../../../../../../../../components/common/styles';

const columns = [
  {
    field: 'taxCode',
    headerName: t`Code`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text'
  },
  {
    field: 'value',
    headerName: t`Value`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({value}) => <Amount {...value} />,
    cellClassName: 'bold'
  },
  {
    field: 'currentValue',
    headerName: t`Current value`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({value}) => <Amount {...value} />,
    cellClassName: 'bold'
  },
  {
    field: 'type',
    headerName: t`Type`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true
  }
];

const RefundSubjectTaxes = () => {

  const taxes = useWatch({name: 'taxes'});

  if (!taxes) return null;

  return (
    <Card elevation={3}>
      <CardHeader title={<Typography fontFamily={'NeoSansProMedium'}>{t`Taxes`}</Typography>}/>
      <Divider/>
      <CardContent>
        <DataGrid
          columns={columns}
          rows={taxes.sort((t1, t2) => t1.type.localeCompare(t2.type)).map((c, idx) => ({
            taxCode: c.taxCode,
            value: {value: c.value, currency: c.currency},
            currentValue: {value: c.currentValue, currency: c.currency},
            type: c.type,
            id: idx
          }))}
          density={'compact'}
          hideFooter
          disableRowSelectionOnClick
          sx={table}
        />
      </CardContent>
    </Card>
  );
};

export default RefundSubjectTaxes;
