import store from './store';

export const actions = {
  app: {
    /** @type {() => null} */
    init: () => null,
    /** @type {() => void} */
    toggleSideBarOpen: () => (store.app.sideBarOpen = !store.app.sideBarOpen),
    /** @type {(open: boolean) => void} */
    setSideBarOpen: (open: boolean) => (store.app.sideBarOpen = open),
    /** @type {(path: string) => void} */
    setSubLinkOpen: (path: string) => (store.app.subLinkOpen = path),
  },
};

export default actions;
