// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import RTKQueryFeedback from '../common/RTKQueryFeedback';
import { useGetAzureUsersMutation } from '../../store/apis/ccs';
import {Autocomplete, TextField} from "@mui/material";

const UserSearch = ({
  onSelectUser,
  label,
  classname,
  renderInputParams,
  autocompleteProps
}) => {
  const [getUsers, { data, error, isLoading }] = useGetAzureUsersMutation();

  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (e, user) => {
    onSelectUser(user.employeeId);
  };

  const searchHandler = useDebouncedCallback((pattern) => {
    getUsers(pattern);
  }, 500);

  const handleInputChange = (e, value) => {
    setInputValue(value);
    if (value.length >= 3) {
      searchHandler(value);
    }
  };

  return (
    <div className={classname}>
      <RTKQueryFeedback error={error} />
      <Autocomplete
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isLoading}
        disableClearable
        options={data || []}
        inputValue={inputValue}
        freeSolo={false}
        filterOptions={(opts, {inputValue= ''}) => opts.filter(o => `${o.employeeId} ${o.name}`.toLowerCase().includes(inputValue.toLowerCase()))}
        onInputChange={handleInputChange}
        onChange={handleChange}
        getOptionSelected={(option, value) =>
          value.employeeId === option.employeeId
        }
        getOptionLabel={(opt) => `${opt?.name} - ${opt?.employeeId}`}
        renderInput={(params) => (
          <TextField
            autoFocus
            placeholder={t`Start type...`}
            {...params}
            variant='outlined'
            label={label}
            size={'small'}
            {...renderInputParams}
          />
        )}
        {...autocompleteProps}
      />
    </div>
  );
};

UserSearch.propTypes = {
  onSelectUser: PropTypes.func.isRequired,
  value: PropTypes.shape({
    employeeId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  classname: PropTypes.string,
  autocompleteProps: PropTypes.object,
  label: PropTypes.string
};

UserSearch.defaultProps = {
  onSelectUser: (employeeID) => {},
  renderInputParams: {},
  classname: 'user-search',
  autocompleteProps: {},
  label: 'Employees'
};

export default UserSearch;
