// @ts-nocheck
import {Box, IconButton} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {t} from 'ttag';
import PaymentOptionSelectorField from '../../../../../../../../../common/PaymentOptionSelectorField';
import Amount from '../Amount';
import CurrentValueTextField from '../../../../../../../../../common/CurrentValueTextField';

export const columns = ({removeHandler, editHandler, editable, disableDeleteBtn = false}) => [
  {
    field: 'indicator',
    headerName: t`Type`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    minWidth: 250,
    flex: 1,
    renderEditCell: ({value, id, field, api}) => (
      <PaymentOptionSelectorField
        label={''}
        value={value}
        size={'small'}
        onChange={(e) => {
          api.setEditCellValue({id, field, value: e.target.value});
          editHandler({id, field, value: e.target.value});
        }}
        fullWidth
        autoOpen
      />
    ),
    cellClassName: 'secondary-text bold'
  },
  {
    field: 'value',
    type: 'number',
    headerName: t`Value`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) => <Amount value={row.value} currency={row.currency}/>,
    cellClassName: 'bold'
  },
  {
    field: 'currentValue',
    type: 'number',
    headerName: t`Current value`,
    sortable: false,
    editable: editable,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) => <Amount value={row.currentValue || 0} currency={row.currency}/>,
    renderEditCell: (params) => {
      return (
        <CurrentValueTextField params={params} editHandler={editHandler}/>
      );
    },
    cellClassName: 'secondary-text bold'
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    hide: !editable,
    type: 'number',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton onClick={() => removeHandler(params.row)} disabled={disableDeleteBtn} size={'small'} aria-label='delete'>
            <Delete fontSize='inherit'/>
          </IconButton>
        </Box>
      )
    }
  },
];
