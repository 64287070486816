// @ts-nocheck
import React from 'react';
import {Box} from "@mui/material";
import PropTypes from 'prop-types';
import {Avatar, ListItem, ListItemAvatar, ListItemText} from '@mui/material';

const Comment = ({ avatar, name, date, comment }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={name} src={avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {name}
            <Box sx={classes.caption} color='textPrimary'>
              {' - '}
              {date}
            </Box>
          </>
        }
        secondary={<React.Fragment>{comment}</React.Fragment>}
      />
    </ListItem>
  );
};

const classes = {
  caption: {
    display: 'inline',
    opacity: 0.5,
    fontSize: '.7em',
    fontStyle: 'italic'
  }
};

Comment.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  comment: PropTypes.string
};

Comment.defaultProps = {
  avatar: 'https://i.imgur.com/mCHMpLT.png'
};

export default Comment;
