import { Typography } from '@mui/material';
import React from 'react';
import {useAccount} from "@azure/msal-react";

const Dashboard = () => {
  const account = useAccount();

  const breadcrumbs = [
    {
      name: 'Home',
      link: '/'
    },
    {
      name: 'Dashboard'
    }
  ];

  return (
      <Typography variant='h2'>Welcome, {account?.name}</Typography>
  );
};

export default Dashboard;
