// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {useDeleteIssueOfficeMutation} from '../../../../store/apis/ccs';
import {Box, DialogActions, DialogContent} from '@mui/material';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogTitle, Typography} from "@mui/material";
import {Cancel} from "@mui/icons-material";

const DeleteIssueOfficeDialog = ({issueOffice, open, onClose, ...rest}) => {

  const [deleteIssueOffice, {data, error, isLoading, isSuccess}] = useDeleteIssueOfficeMutation();

  return (
    <>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading} />
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Cancel color={'error'}/>
          <Typography variant='h2' color={'error'} sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Remove Issue office`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t`Do you really want to delete this issue office?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box display='flex' mx={1} gap={2}>
            <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
            <Button variant={'contained'} color={'error'} onClick={() => deleteIssueOffice(issueOffice).then(r => !r.error && onClose())}>{t`Delete`}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteIssueOfficeDialog.propTypes = {
  issueOffice: PropTypes.shape({
    issueOfficeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteIssueOfficeDialog.defaultProps = {
  issueOffice: null,
  open: true,
  onClose: null,
};

export default DeleteIssueOfficeDialog;
