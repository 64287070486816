import React from 'react';
import * as Sentry from "@sentry/react";
import reportWebVitals from './reportWebVitals';
import App from './App';
import SnackbarProvider from "./providers/SnackbarProvider";
import AzureAuthProvider from "./providers/AzureAuthProvider";
import ReduxProvider from "./store/redux/Provider";
import ThemeProvider from "./theme/ThemeProvider";
import {createRoot} from "react-dom/client";

Sentry.init({
    dsn: "https://018171b2030af5518fc1b23ef1e64dd8@o4507612011167744.ingest.de.sentry.io/4507969115258960",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/dev-ccs\.sata\.pt/, /^https:\/\/ccs\.sata\.pt/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <AzureAuthProvider>
        <ReduxProvider>
            <ThemeProvider>
                <SnackbarProvider>
                    <App/>
                </SnackbarProvider>
            </ThemeProvider>
        </ReduxProvider>
    </AzureAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
