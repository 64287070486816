import {t} from 'ttag';
import React from 'react';
import {Controller} from "react-hook-form";
import {DeleteOutlined} from "@mui/icons-material";
import {GridActionsCellItem} from "@mui/x-data-grid";
import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DATE_FORMAT} from '../../../../../utils/datetime/datetime-utils';
import BeneficiaryFacilityTypeSelector
  from '../../../../components/beneficiary-facility-type-selector/BeneficiaryFacilityTypeSelector';
import GenderSelector from '../../../../components/gender-selector/GenderSelector';

export const columns = ({updateHandler, updateCompanion, deleteHandler, control}: any) => [
  {
    flex: 1,
    minWidth: 200,
    field: 'beneficiaryFamilyMember.familyMember.name',
    headerName: t`Name`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}: any) => row.new ?
        <Controller
            name={'name'}
            control={control}
            render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                    value={value}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                      updateCompanion(row);
                    }}
                    size={'small'}
                    color={'primary'}
                    error={Boolean(error)}
                />
            )}
        />
        : <Tooltip title={row.beneficiaryFamilyMember.familyMember.name}><Typography sx={{textTransform: 'capitalize', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}} variant={'body2'} color='secondary'>{row.beneficiaryFamilyMember.familyMember.name.toLowerCase()}</Typography></Tooltip>
  },
  {
    flex: 1,
    minWidth: 300,
    field: 'beneficiaryFamilyMember.beneficiary.name',
    headerName: t`Beneficiary`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}: any) => row.beneficiaryFamilyMember?.beneficiary?.name && !row.new? <Tooltip title={row.beneficiaryFamilyMember.beneficiary.name}><Typography variant={'body2'} color='secondary'>{row.beneficiaryFamilyMember.beneficiary.name}</Typography></Tooltip> :
        row.new ?
          <Stack direction={'row'} height={'34px'} py={2} spacing={1} >
            <Controller
              name={'gender'}
              control={control}
              render={({field, fieldState: {error}}) => (
                <GenderSelector value={field.value} onChange={(value: string) => {
                  field.onChange(value);
                  updateCompanion(row);
                }} error={error} />
              )}
            />
            <Controller
              name={'birthDate'}
              control={control}
              render={({field, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={localStorage.getItem('LANG') === 'pt' ? 'PT-pt': 'EN-us'}>
                  <DatePicker  disableFuture onChange={(value) => {
                    field.onChange(value.toFormat(DATE_FORMAT));
                    updateCompanion(row);
                  }} inputFormat={'yyyy-MM-dd'} value={field.value} renderInput={(props) =>  <TextField {...props} label={t`Birthday`} size={'small'}/>}/>
                </LocalizationProvider>
              )}
            />
          </Stack>
          :
        <Typography variant={'body2'} color='secondary'>{row.new || row.new === null ? t`Companion`: t`No match`}</Typography>
  },
  {
    flex: 1,
    minWidth: 150,
    field: 'beneficiaryFamilyMember.familyMember.idNumber',
    headerName: t`Identification Number`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}: any) => row.new ?
        <Controller
            name={'idNumber'}
            control={control}
            render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                    value={value}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                      updateCompanion(row);
                    }}
                    size={'small'}
                    color={'primary'}
                    error={Boolean(error)}
                />
            )}
        />
        : <Typography variant={'body2'} color='secondary'>{row.beneficiaryFamilyMember.familyMember.idNumber}</Typography>
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'allowanceDefinitionUsage.allowanceDefinition.facilityType.id',
    headerName: t`Facility Type`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}: any) => <BeneficiaryFacilityTypeSelector row={row} onChange={updateHandler}/>
  },
  {
    type: 'actions',
    field: 'actions',
    headerName: t`Actions`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}: any) => [
      row.new && <GridActionsCellItem key={0} icon={<DeleteOutlined color={'secondary'}/>} onClick={() => deleteHandler(row)} label='Delete'/>,
    ]
  }
];

export const helper = {
  columns
};

export default helper;
