import React from 'react';
import {Box} from '@mui/material';
import Appbar from './main-layout/Appbar';
import Sidebar from './main-layout/Sidebar';

interface MainLayoutProps {
    children: React.ReactNode;

}

export const MainLayout = ({children}: MainLayoutProps) => {
  return (
    <Box sx={{ display: '-webkit-box', backgroundColor:'#F9FAFC' }}>
      <Appbar/>
      <Sidebar/>
        <Box component={'main'} sx={{ flexGrow: 1, p: 3, marginTop: '3.4rem', minHeight: 'calc(100vh - 119px)'}}>
            {children}
        </Box>
    </Box>
  );
};

export default MainLayout;
