// @ts-nocheck
export const type = 'refund';
export const refundSubjectType = 'refund-subject';
export const endpoints = (builder) => ({
  // Get refunds list
  getRefunds: builder.query({
    query: ({ page, filterPattern, filterRange, filterModified }) => {
      let url = `/refunds?page=${page}`;
      if (filterPattern) {
        url = url.concat(`&pattern=${filterPattern}`);
      }
      if (filterRange) {
        url = url.concat(
          `&startDate=${filterRange?.startDate}&endDate=${filterRange?.endDate}`
        );
      }
      if (filterModified) {
        url = url.concat(`&modified=${filterModified}`);
      }
      return { url };
    },
    providesTags: () => {
      return [{ type, id: 'list' }];
    }
  }),

  // Get refund by ID
  getRefund: builder.query({
    query: (refundId) => ({ url: `/refunds/${refundId}` }),
    providesTags: ({ refundId }) => {
      return [{ type, id: refundId }];
    }
  }),
  // Get refund by ID
  getRefundHistory: builder.query({
    query: (refundId) => ({
      url: `/refunds/${refundId}/history`,
      destructFn: (d) => d?.data?.data
    }),
    providesTags: ({ refundId }) => {
      return [{ type: `${type}_history`, id: refundId }];
    }
  }),
  // Get refund by ID
  getRefundComments: builder.query({
    query: (refundId) => ({
      url: `/refunds/${refundId}/comments`,
      destructFn: (d) => d?.data?.data
    }),
    providesTags: ({ refundId }) => {
      return [{ type: `${type}_comments`, id: refundId }];
    }
  }),

  // Get refund subject by identifier
  getRefundSubject: builder.mutation({
    query: (refundSubject) => ({
      url: '/refund-subjects/import',
      method: 'POST',
      body: refundSubject
    }),
    transformResponse: (data) => {
      const responseData = {
        ...data,
        passengers: data.passengers.map((p, idx) => ({ ...p, localId: idx })),
        coupons: data.coupons.map((c, idx) => ({ ...c, localId: idx })),
        amounts: data.amounts.map((a, idx) => ({ ...a, localId: idx })),
        taxes: data.taxes.map((tx, idx) => ({ ...tx, localId: idx })),
        paymentOptions: data.paymentOptions.map((p, idx) => ({
          ...p,
          localId: idx
        }))
      };
      return { ...responseData, original: { ...responseData } };
    },
    providesTags: ({ refundSubjectId }) => {
      return [{ refundSubjectType, id: refundSubjectId }];
    },
    keepUnusedDataFor: 80
  }),

  // Create refund
  createRefund: builder.mutation({
    query: (refund) => ({
      url: '/refunds',
      method: 'POST',
      body: refund
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  // Edit refund
  editRefund: builder.mutation({
    query: (refund) => ({
      url: `/refunds/${refund.refundId}`,
      method: 'PATCH',
      body: refund
    }),
    invalidatesTags: (result, error, { refundId }) => {
      return [{ type, id: refundId }];
    }
  }),

  // Delete refund
  deleteRefund: builder.mutation({
    query: (refund) => ({
      url: `/refunds/${refund.refundId}`,
      method: 'DELETE'
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),
  // PRINT REPORT
  printRefund: builder.mutation({
    query: (refundId) => ({
      url: `/refunds/${refundId}/generate-report?disposition=inline`,
      method: 'GET',
      responseType: 'blob'
    })
  }),

  // SEND BY EMAIL REPORT
  sendByEmailRefund: builder.mutation({
    query: (refundId) => ({
      url: `/refunds/${refundId}/send-email`,
      method: 'GET'
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  // Add Attachments
  getAttachments: builder.query({
    query: (refundId) => ({
      url: `/refunds/${refundId}/attachments`,
      method: 'GET'
    }),
    providesTags:()=> {return [{ type: `${type}_attachments`, id: 'list' }]}
  }),

  // Add Attachments
  addAttachments: builder.mutation({
    query: (params) => ({
      url: `/refunds/${params.refundId}/attachments`,
      method: 'POST',
      body: params.files
    }),
    invalidatesTags: [{ type: `${type}_attachments`, id: 'list' }]
  }),

  // Add Attachments
  downloadAttachments: builder.mutation({
    query: ({ refundId, attachmentId, path }) => ({
      url: `/refunds/${refundId}/attachments/${attachmentId}?disposition=inline`,
      method: 'POST',
      body: { path },
      responseType: 'blob'
    }),
    invalidatesTags: [{ type: `${type}_attachments`, id: 'list' }]
  }),

  // cancel refund
  cancelRefund: builder.mutation({
    query: (refundId) => ({
      url: `/refunds/${refundId}/cancel`,
      method: 'PATCH'
    }),
    invalidatesTags: (result, error, { refundId }) => {
      return [
        { type, id: refundId },
        { type: `${type}_history`, id: refundId }
      ];
    }
  }),

  // close refund
  closeRefund: builder.mutation({
    query: (refundId) => ({
      url: `/refunds/${refundId}/process`,
      method: 'PATCH'
    }),
    invalidatesTags: (result, error, { refundId }) => {
      return [
        { type, id: refundId },
        { type: `${type}_history`, id: refundId }
      ];
    }
  })
});

export default endpoints;
