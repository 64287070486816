// @ts-nocheck
import {t} from 'ttag';
import {Box, IconButton} from "@mui/material";
import {Check, Close, DeleteOutlined, EditOutlined} from "@mui/icons-material";
import React from "react";

export const columns = ({editHandler, removeHandler}) => [
  {
    field: 'officeCode',
    headerName: t`Office Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 125
  },
  {
    field: 'companyCode',
    headerName: t`Company Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text',
    flex: 1,
    minWidth: 125
  },
  {
    field: 'ticketCode',
    headerName: t`Ticket Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text',
    flex: 1,
    minWidth: 100
  },
  {
    field: 'currencyCode',
    headerName: t`Currency Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text',
    flex: 1,
    minWidth: 125
  },
  {
    field: 'description',
    headerName: t`Description`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 150
  },
  {
    field: 'active',
    headerName: t`Active`,
    type: 'boolean',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 75,
    renderCell: ({ row }) => (
      !row.disabled ? <Check color={'success'}/> : <Close color={'error'}/>
    )
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'actions',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    minWidth: 100,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton onClick={() => editHandler(params.row)} color={'secondary'} aria-label='edit'>
            <EditOutlined fontSize='inherit' />
          </IconButton>
          <IconButton onClick={() => removeHandler(params.row)} color={'secondary'} aria-label='delete'>
            <DeleteOutlined fontSize='inherit'/>
          </IconButton>
        </Box>
      )
    }
  },
];

export default columns;
