import {t} from 'ttag';
import React from 'react';
import {Check} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useCancelAuthorizationMutation} from '../../../../api';
import {IAuthorization} from '../../../../api/transportFacilityEndpoints.types';
import RTKQueryFeedback from '../../../../../components/common/RTKQueryFeedback';
import {enqueueSnackbar} from 'notistack';

export const TransportFacilitiesCancel = ({data, onClose, fullScreen}: {data: any, onClose: () => void, fullScreen: boolean}) => {
  const [onCancel, {data: dataCancel, error, isLoading, isSuccess}] = useCancelAuthorizationMutation();

  const handleSave = () => {
    onCancel(data).then((e: {data?: IAuthorization; error?: any}) => {
      if (!e?.error) {
        onClose();
        enqueueSnackbar(t`Transport Facility canceled successfully`, {variant: 'success'});
      };
    });
  };

  return (
    <>
      <Dialog fullScreen={fullScreen} onClose={onClose} open>
        <DialogTitle color={'secondary'} variant={'h4'}>{t`Cancel Transport Facility`}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{t`Do you really want to cancel this Transport Facility?`}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button variant={'outlined'} color={'inherit'} onClick={onClose} autoFocus>
            {t`Cancel`}
          </Button>
          <Button
            startIcon={<Check />}
            onClick={handleSave}
            variant={'contained'}
            color={'primary'}
            autoFocus
          >{t`Confirm`}</Button>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback
        data={dataCancel}
        error={error}
        isLoading={isLoading}
      />
    </>
  );
};

export default TransportFacilitiesCancel;
