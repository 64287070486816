// @ts-nocheck
import React from 'react';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {useWatch} from 'react-hook-form';
import {DataGrid} from '@mui/x-data-grid';
import table from '../../../../../../../../../../components/common/styles';

const columns = [
  {
    field: 'route',
    headerName: t`Route`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'flight_number',
    headerName: t`Flight number`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text'
  },
  {
    field: 'fare_basis',
    headerName: t`Fare basis`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text'
  },
  {
    field: 'coupon_status',
    headerName: t`Coupon status`,
    width: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'date',
    headerName: t`Date`,
    width: 100,
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    sortable: false,
    disableColumnMenu: true
  }
];

const RefundSubjectRoutes = () => {

  const coupons = useWatch({name: 'coupons'});

  if (!coupons) return null;

  return (
    <Card elevation={3}>
      <CardHeader title={<Typography fontFamily={'NeoSansProMedium'}>{t`Itinerary`}</Typography>}/>
      <Divider/>
      <CardContent>
        <DataGrid
          columns={columns}
          rows={coupons.map((c, idx) => ({
            id: idx,
            route: `${c.originAirport || ''} - ${c.destinationAirport || ''}`,
            flight_number: c.flightNumber,
            fare_basis: c.fareBasis,
            coupon_status: c.couponStatus,
            date: c.datetime
          }))}
          density={'compact'}
          disableRowSelectionOnClick
          hideFooter
          sx={table}
        />
      </CardContent>
    </Card>

  );
};

RefundSubjectRoutes.propTypes = {
  formik: PropTypes.shape({}),
};

export default RefundSubjectRoutes;
