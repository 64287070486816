import React, {useState} from 'react';
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {AccountCircle, ExpandLess, ExpandMore, Logout, Person, Translate} from '@mui/icons-material';
import {t} from 'ttag';
import {useNavigate} from 'react-router-dom';
import {useAccount} from "@azure/msal-react";
import {changeLanguage, LOCALE_DEFAULT, LOCALES} from "../../config/i18n";
import {Theme} from "@mui/material";

interface UserMenuProps {
  onChangeLanguage: (option: string) => void,
  hideLanguageSelector: boolean
}

const changeLanguageDefaultProp = (l: string) => {
  changeLanguage(l);
  window.location.reload();
}

const UserMenu = ({onChangeLanguage = changeLanguageDefaultProp, hideLanguageSelector = false} : UserMenuProps) => {
  const navigate = useNavigate();
  const account = useAccount();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const expandIcon = anchorEl ? <ExpandLess sx={styles.expandIcon} /> : <ExpandMore sx={styles.expandIcon} />;

  return (
    <Box sx={styles.wrapper}>
      <Button
        variant={'text'}
        sx={styles.root}
        startIcon={<AccountCircle />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Box display='flex' alignItems='center' width='100%'>
          {!isMobile && (
            <Box display='flex' flexDirection='column' flexGrow='1' justifyContent='flex-start'>
              <Typography component='p' variant='body2' sx={styles.name} noWrap>
                {account?.name}
              </Typography>
              <Typography component='p' variant='caption' sx={styles.email} noWrap>
                {account?.username}
              </Typography>
            </Box>
          )}
          <Box sx={styles.indicator}>{expandIcon}</Box>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {isMobile && (
          <Box>
            <MenuItem>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <Stack>
                <Typography component='p' variant='body2' sx={styles.name} noWrap>
                  {account?.name}
                </Typography>
                <Typography component='p' variant='caption' sx={styles.email} noWrap>
                  {account?.username}
                </Typography>
              </Stack>
            </MenuItem>
            <Divider sx={{margin: 0}} />
          </Box>
        )}
        {!hideLanguageSelector && (
            <Accordion elevation={0} sx={{marginTop: '-8px'}} disableGutters>
              <AccordionSummary sx={styles.accordionSummary} expandIcon={<ExpandMore />}>
                <MenuItem disableRipple={true} sx={styles.languageMenuItem}>
                  <ListItemIcon>
                    <Translate/>
                  </ListItemIcon>
                    {t`Language`}
                </MenuItem>
              </AccordionSummary>
              <AccordionDetails>
                <MenuItem
                    onClick={() => onChangeLanguage(LOCALES.PT)}
                    sx={{fontFamily: LOCALE_DEFAULT === LOCALES.PT ? 'NeoSansProMedium' : 'default'}}
                >
                  {t`PORTUGUESE`}
                </MenuItem>
                <MenuItem
                    onClick={() => onChangeLanguage(LOCALES.EN)}
                    sx={{fontFamily: LOCALE_DEFAULT === LOCALES.EN ? 'NeoSansProMedium' : 'default'}}
                >
                  {t`ENGLISH`}
                </MenuItem>
              </AccordionDetails>
            </Accordion>

        )}
        {!hideLanguageSelector && <Divider />}
        <MenuItem onClick={() => navigate('/')}>
          <ListItemIcon>
            <Person fontSize='small' />
          </ListItemIcon>
          {t`Account Details`}
        </MenuItem>
        <MenuItem onClick={() => navigate('/logout')}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          {t`Logout`}
        </MenuItem>
      </Menu>
    </Box>
  );
};

const styles = {
  wrapper: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomWidth: 0
  },
  root: {
    margin: 0,
    height: '3.15rem',
    maxWidth: '14.875rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      paddingRight: (theme: Theme) => theme.spacing(1 / 2),
      '& .MuiSvgIcon-root': {
        fontSize: '2.1875rem'
      }
    }
  },
  smallButton: {
    width: 'auto',
    '& .MuiButton-startIcon': {
      paddingRight: 0,
      marginRight: 0
    }
  },
  name: {
    fontSize: '0.9375rem',
    maxWidth: '7.25rem',
    textTransform: 'none',
    paddingRight: (theme: Theme) => theme.spacing(1 / 2),
    color: (theme: Theme) => theme.palette.text.primary
  },
  email: {
    lineHeight: 1,
    maxWidth: '7.25rem',
    textTransform: 'none',
    textAlign: 'left',
    paddingRight: (theme: Theme) => theme.spacing(1 / 2),
    color: (theme: Theme) => theme.palette.text.disabled
  },
  expandIcon: {
    color: 'primary',
  },
  indicator: {
    maxHeight: '2.125rem',
    overflow: 'hidden',
    paddingTop: (theme: Theme) => theme.spacing(1 / 2),
  },
  popover: {
    maxWidth: '14.7rem',
    width: '14.875rem',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  accordionSummary: {
    padding: 0,
    pr: 2,
    '&:hover': {backgroundColor: 'rgba(0,0,0,0.05)'},
    '& .MuiAccordionSummary-content': {
      margin: 0,
    }
  },
  languageMenuItem: {
    '&:hover': {
      backgroundColor: 'inherit'
    }
  }
};

export default UserMenu;
