// @ts-nocheck
import React, {useState} from 'react';
import {t} from 'ttag';
import {MenuItem} from '@mui/material';
import Dropdown from '../../../../../components/common/Dropdown';
import {useFormContext, useWatch} from 'react-hook-form';

const RecipientSelector = () => {
  const {setValue} = useFormContext();
  const [recipient, setRecipient] = useState({});
  const refundSubjects = useWatch({name: 'refundSubjects'});

  const recipients = refundSubjects.map(rs => {
    return rs.passengers?.reduce((acc, pax) => {
      return {
        ...pax,
        name: pax.firstName.concat(` ${pax.lastName}`) || undefined,
        nif: pax.taxPayerNumber || undefined
      }
    }, []);
  });

  return (
    <Dropdown
      label={t`Recipient`}
      color={'primary'}
      variant={'outlined'}
      onChange={(e) => {
        const r = e.target.value;
        setRecipient(r);
        setValue('recipient.name', r.name);
        setValue('recipient.nif', r.nif);
      }}
      value={recipient}
      fullWidth
      SelectProps={{
        renderValue: (value) => value?.name || t`Select one...`
      }}
    >
      {recipients?.map((recipient, idx) => {
        return (
          <MenuItem key={idx} value={recipient}>
            {recipient.name}
          </MenuItem>
        );
      })}
    </Dropdown>
  );
};

export default RecipientSelector;
