import {t} from 'ttag';
import * as Yup from 'yup';
import {createDatetime} from '../../../../../utils/datetime/datetime-utils';
import {DateTime} from 'luxon';

export const isAdultPresentWithInfant = (authorizations: any[], travelDate: any) => {
  let hasAdult = false;
  let hasInfant = false;
  const travelDateTime = createDatetime(travelDate);

  authorizations.forEach(function (authorization: any) {
    const birthdayDateTime = DateTime.fromISO(authorization.beneficiaryFamilyMember?.familyMember?.birthday);
    const age = travelDateTime.diff(birthdayDateTime, 'years').years;

    if (age < 2) {
      hasInfant = true;
    }

    if (age >= 18) {
      hasAdult = true;
    }
  });

  return !hasInfant || hasAdult;
};

export const transportFacilityValidationSchema = Yup.object().shape({
  colaborator: Yup.object().required(t`Este campo é obrigatório`),
  travelDate: Yup.string().required(t`Este campo é obrigatório`),
  observations: Yup.string().nullable(),
  travelCompany: Yup.string().required(t`Must be defined`),
  motive: Yup.string().required(t`Este campo é obrigatório`),
  authorizations: Yup.array()
    .min(1, t`Must have atleast one authorization`)
    .required(t`Este campo é obrigatório`)
    // .test('is-adult-present-with-infant', t`Must have atleast one adult for each infant`, function (authorizations: any) {
    //   return authorizations && isAdultPresentWithInfant(authorizations?.filter((auth)=> auth.selectedFacilityId), this.parent.travelDate);
    // })
});
