export class AxiosError extends Error {
  private status: any;
  private data: any;
  constructor({message, status, data}: {message: string, status: any, data: any}) {
    super(message || data?.description);
    this.status = status;
    this.data = data;
  }
}

export default AxiosError;
