import React from 'react';
import {Skeleton} from "@mui/material";

const AttachmentsDownloadsSkeleton = () => {
  return (
    <>
      <Skeleton />
      <Skeleton />
    </>
  );
};

AttachmentsDownloadsSkeleton.propTypes = {};

AttachmentsDownloadsSkeleton.defaultProps = {};

export default AttachmentsDownloadsSkeleton;
