// @ts-nocheck
export const type = 'residentValidate';
export const endpoints = (builder) => ({
  // Validate resident NIFs
  residentValidate: builder.mutation({
    query: ({agent, validations}) => ({
      url: '/resident-validate',
      method: 'POST',
      body: {
        agent,
        validations
      }
    }),
  }),

  // Check responsibility term acceptance
  checkResponsibilityTerm: builder.query({
    query: () => ({ url: `/responsibility-term`}),
    providesTags: () => [{ type, id: 'responsibility-term' }]
  }),

  // Update responsibility term acceptance
  updateResponsibilityTerm: builder.mutation({
    query: ({message, providedUsername, isAccept}) => ({
      url: '/responsibility-term',
      method: 'POST',
      body: {
        message,
        providedUsername,
        isAccept
      }
    }),
    invalidatesTags: () => [{ type, id: 'responsibility-term' }]
  }),
});

export default endpoints;
