import {setDefaultLang, useLocale as setLocale} from 'ttag';

export const LOCALE_KEY = 'LANG';

export const LOCALES = {
    EN: 'en',
    PT: 'pt'
};

export const LOCALE_CANDIDATE = String(localStorage.getItem(LOCALE_KEY) || navigator.language).substring(0, 2).toLowerCase();

export const LOCALE_DEFAULT = Object.values(LOCALES).includes(LOCALE_CANDIDATE) ? LOCALE_CANDIDATE : LOCALES.EN;


export const changeLanguage = (lang: string) => {
    localStorage.setItem(LOCALE_KEY, lang);
    setLocale(lang);
}

export const i18nInit = () => {
    setDefaultLang(LOCALE_DEFAULT);
};

(() => {
    i18nInit();
    changeLanguage(LOCALE_DEFAULT);
})();

