import React, {useRef, useState} from 'react';
import {t} from 'ttag';
import {Box, Button, Grid, MenuItem, Switch, TextField, Typography, useMediaQuery} from '@mui/material';
import {Controller, useFieldArray, useForm, useFormContext} from 'react-hook-form';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DataGrid} from '@mui/x-data-grid';
import {Theme} from '@mui/system';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {beneficiaryValidationSchema} from './schemas/beneficiaryValidationSchema';
import {useDebouncedCallback} from 'use-debounce';
import {createDatetime, DATE_FORMAT, DATETIME_FORMAT, nowTz} from '../../../../utils/datetime/datetime-utils';
import EmployeeSelector from '../../../components/employee-selector/EmployeeSelector';
import BeneficiariesListMobile from './helpers/BeneficiariesListMobile';
import {columns} from './helpers/beneficiariesListHelper';
import {ICompany} from '../../../api/companyEndpoints.types';
import {IMotive} from '../../../api/transportFacilityEndpoints.types';

type TransportFacilityFormProps = {
  companies: ICompany[];
  motives: IMotive[];
  onSetSelectedFacilityId: (id: string|null) => void;
};

const MOTIVES = {
  Vacations: t`Vacations`,
  Private: t`Private`,
  Other: t`Other`,
} as {[key: string]: string};

const TransportFacilityForm = ({companies, motives, onSetSelectedFacilityId}: TransportFacilityFormProps) => {
  const {control} = useFormContext();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {fields, update} = useFieldArray({
    control,
    keyName: 'tableUniqueId',
    name: 'authorizations'
  });

  const methods = useForm({
    resolver: yupResolver(beneficiaryValidationSchema),
    defaultValues: {name: '', idNumber: '',gender: '', birthDate: createDatetime(nowTz()).toFormat(DATE_FORMAT)}
  });
  const [selectionModel, setSelectionModel] = useState<any>([-1]);

  const updateHandler = (v: any) => {
    const isCollaborator = v.beneficiaryFamilyMember?.familyMember.relationshipType === 'COLABORADOR';
    const field = fields.find((f) => f.tableUniqueId === v.tableUniqueId);
    v.selectedFacilityId
      ? isCollaborator || setSelectionModel([...selectionModel, fields.indexOf(field || v)])
      : isCollaborator || setSelectionModel([...selectionModel.filter((s: any) => s !== fields.indexOf(field || v))]);
    if (v.selectedFacilityId && isCollaborator) {
      onSetSelectedFacilityId(v.selectedFacilityId);
      setSelectionModel([fields.indexOf(field || v)]);
    }
    if (!v.selectedFacilityId && isCollaborator) {
      onSetSelectedFacilityId(null);
      setSelectionModel([-1]);
    }
    update(fields.indexOf(field || v), v);
  };
  const updateCompanion = useDebouncedCallback((v: any) => {
    const isCollaborator = v.beneficiaryFamilyMember?.familyMember.relationshipType === 'COLABORADOR';
    const field = fields.find((f) => f.tableUniqueId === v.tableUniqueId);
    v.selectedFacilityId
      ? isCollaborator || setSelectionModel([...selectionModel, fields.indexOf(field || v)])
      : isCollaborator || setSelectionModel([...selectionModel.filter((s: number) => s !== fields.indexOf(field || v))]);
    if (v.selectedFacilityId && isCollaborator) {
      onSetSelectedFacilityId(v.selectedFacilityId);
      setSelectionModel([fields.indexOf(field || v)]);
    }
    if (!v.selectedFacilityId && isCollaborator) {
      onSetSelectedFacilityId(null);
      setSelectionModel([-1]);
    }
    methods.handleSubmit((values) => {
      update(fields.indexOf(field || v), {
        ...v,
        beneficiaryFamilyMember: {
          ...v?.beneficiaryFamilyMember,
          familyMember: {
            ...v?.beneficiaryFamilyMember.familyMember,
            name: values.name,
            gender: values.gender,
            birthday: values.birthDate && createDatetime(values.birthDate).toFormat(DATE_FORMAT),
            idNumber: values.idNumber
          }
        },
        new: true
      });
    },(errors) => console.error(errors))();
  }, 600);
  const deleteHandler = (v: any) => {
    const field = fields.find((f) => f.tableUniqueId === v.tableUniqueId);
    methods.reset({name: '', idNumber: '',gender: '', birthDate: createDatetime(nowTz()).toFormat(DATE_FORMAT)});
    update(fields.indexOf(field || v), {...v, new: false});
  };
  const ref = useRef(null);
  const handleClick = () => {
    //@ts-ignore
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <Grid rowSpacing={3} columnSpacing={3} container p={2}>
      <Grid xs={12} md={12} xl={6} item>
        <Controller
          name={'colaborator'}
          control={control}
          defaultValue={
            sessionStorage.getItem('selectedEmployeeId') &&
            JSON.parse(sessionStorage.getItem('selectedEmployeeId') || '{}')
          }
          render={({field: {onChange, value}}) => (
            <>
              <Typography sx={styles.label} variant={'body1'}>{t`Employee`}</Typography>
              <EmployeeSelector value={value} onChange={onChange} />
            </>
          )}
        />
      </Grid>
      <Grid xs={12} md={12} xl={12} item>
        <Typography variant={'h4'} sx={{fontFamily: 'NeoSansProBold', mt: 2}}>{t`Trip details:`}</Typography>
      </Grid>
      <Grid xs={12} md={12} xl={3} item>
        <Controller
          name={'travelDate'}
          control={control}
          defaultValue={''}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <>
              <Typography sx={styles.label} variant={'body1'}>{t`Trip start`}</Typography>
              <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={localStorage.getItem('LANG') === 'pt' ? 'PT-pt': 'EN-us'}>
                <DatePicker
                  inputFormat={'yyyy-MM-dd'}
                  value={value}
                  onChange={(e: any) => onChange(e.toFormat(DATETIME_FORMAT))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{...params.inputProps, readOnly: true}}
                      fullWidth
                      size={'small'}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                  disablePast
                  // minDate={createDatetime(nowTz(undefined, DATETIME_FORMAT)).plus({days: 1}).toFormat(DATETIME_FORMAT)}
                  maxDate={
                    createDatetime(nowTz(undefined, DATETIME_FORMAT)).month > 8
                      ? createDatetime(createDatetime(nowTz(undefined, DATETIME_FORMAT)).year + 1 + '-12-31 00:00:00').toFormat(DATETIME_FORMAT)
                      : createDatetime(createDatetime(nowTz(undefined, DATETIME_FORMAT)).year + '-12-31 00:00:00').toFormat(DATETIME_FORMAT)
                  }
                  disableMaskedInput
                />
              </LocalizationProvider>
            </>
          )}
        />
      </Grid>
      <Grid xs={12} md={12} xl={3} item>
        <Controller
          name={'travelCompany'}
          control={control}
          defaultValue={''}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <>
              <Typography sx={styles.label} variant={'body1'}>{t`Travelling company`}</Typography>
              <TextField
                value={value}
                size={'small'}
                color={'primary'}
                variant={'outlined'}
                error={Boolean(error)}
                helperText={error?.message}
                onChange={(e: any) => onChange(e)}
                select
                SelectProps={{displayEmpty: true}}
                InputProps={{
                  style: { color: value ? 'inherit' : 'rgba(98, 98, 98, 1)' }
                }}
                fullWidth
              >
                <MenuItem key={-1} value={''} sx={{display: 'none'}}>
                  {t`Select`}
                </MenuItem>
                {companies.map(
                  (company: {id: string, name: string, canBeTravelled: boolean}) =>
                    company.canBeTravelled && (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    )
                )}
              </TextField>
            </>
          )}
        />
      </Grid>
      <Grid xs={12} md={12} xl={2} item>
        <Controller
          name={'motive'}
          control={control}
          render={({field, fieldState: {error}}) => (
            <>
              <Typography sx={styles.label} variant={'body1'}>{t`Motive`}</Typography>
              <TextField
                {...field}
                size={'small'}
                color={'primary'}
                variant={'outlined'}
                error={Boolean(error)}
                helperText={error?.message}
                select
                SelectProps={{displayEmpty: true}}
                fullWidth
                InputProps={{
                  style: { color: field.value ? 'inherit' : 'rgba(98, 98, 98, 1)' }
                }}
              >
                <MenuItem key={-1} value={''} sx={{display: 'none'}}>
                  {t`Select`}
                </MenuItem>
                {motives.map((motive: {id: string, name: string}) => (
                  <MenuItem key={motive.id} value={motive.id}>
                    {MOTIVES[motive.name]}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
        />
      </Grid>
      <Grid xs={12} md={12} xl={12} item>
        <Box display={isMobile ? 'inherit' : 'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography
            variant={'h4'}
            sx={{fontFamily: 'NeoSansProBold', mb: isMobile ? 1 : 0}}
          >{t`Beneficiary(s):`}</Typography>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
            <Controller
              name={'isColdVacation'}
              control={control}
              render={({field: {onChange, value}}) => (
                <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                  <Typography
                    sx={{...styles.label, mt: 0.75}}
                    variant={'body1'}
                    align={'center'}
                  >{t`Include low season vacations`}</Typography>
                  <Switch value={value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}></Switch>
                </Box>
              )}
            />
            <Button
              variant={'outlined'}
              color={'primary'}
              disabled={Boolean(fields.find((f: any) => f.new)) || !Boolean(fields.find((f: any) => f.new === false))}
              sx={{
                fontFamily: 'NeoSansProMedium',
                borderWidth: 2,
                '&:hover': {borderWidth: 2},
                '&:disabled': {borderWidth: 2}
              }}
              onClick={() => {
                const newField = fields.find((f: any) => f.new === false);
                newField && methods.reset({name: '', idNumber: '', birthDate: createDatetime(nowTz()).toFormat(DATE_FORMAT)});
                newField &&
                  update(fields.indexOf(newField), {
                    ...newField,
                    new: true
                  });
                isMobile && setTimeout(() => handleClick(), 250);
              }}
            >
              {t`Add`}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} md={12} xl={12} item>
        <Controller
          name={'authorizations'}
          control={control}
          render={({fieldState: {error}}) =>
            isMobile ? (
              <BeneficiariesListMobile
                fields={fields.filter((f: any) => f.new !== false) || []}
                selectionModel={selectionModel}
                setSelectionModel={(s: any) => setSelectionModel(s)}
                setSelectedFacilityId={(id: string) => onSetSelectedFacilityId(id)}
                control={methods.control}
                updateCompanion={updateCompanion}
                deleteHandler={deleteHandler}
                updateHandler={updateHandler}
                error={error}
                innerRef={ref}
              />
            ) : (
              <>
                {fields && (
                <DataGrid
                  rows={fields.filter((f: any) => f.new !== false) || []}
                  paginationMode={'server'}
                  rowCount={fields?.length || 0}
                  getRowId={(row: any) => {
                    return fields.indexOf(row);
                  }}
                  columns={columns({updateHandler, updateCompanion, deleteHandler, control: methods.control})}
                  getRowClassName={(params: any) => (params.id % 2 === 0 ? 'even' : 'odd')}
                  sx={styles.table}
                  rowSelectionModel={selectionModel}
                  onRowSelectionModelChange={(params) => {
                    const unselectedRows = fields.filter((row) => !new Set(params).has(fields.indexOf(row)));
                    unselectedRows.map((row: any) => updateHandler({...row, selectedFacilityId: undefined}));
                    setSelectionModel(params);
                  }}
                  checkboxSelection
                  disableColumnSelector
                  disableRowSelectionOnClick
                  autoPageSize
                  autoHeight
                  pagination
                  hideFooter
                  components={{
                    NoRowsOverlay: () => (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'flex-end'}
                        height={'60%'}
                        width={'100%'}
                        position={'absolute'}
                      >
                        <Typography
                          variant={'body2'}
                          color={'secondary'}
                          align={'center'}
                        >{t`Unable to find data`}</Typography>
                      </Box>
                    ),
                    NoResultsOverlay: () => (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'flex-end'}
                        height={'60%'}
                        width={'100%'}
                        position={'absolute'}
                      >
                        <Typography
                          variant={'body2'}
                          color={'secondary'}
                          align={'center'}
                        >{t`Unable to find data`}</Typography>
                      </Box>
                    )
                  }}
                />
                )}
                <Typography sx={{...styles.label, color: 'red', paddingBottom: 0, pt: 0.5}} variant={'body2'}>
                  {error?.message}
                </Typography>
              </>
            )
          }
        />
      </Grid>
      <Grid xs={12} md={12} xl={12} item>
        <>
          <Controller
            name={'observations'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <>
                <Typography sx={styles.label} variant={'body1'}>{t`Observations`}</Typography>
                <TextField
                  {...field}
                  size={'small'}
                  color={'primary'}
                  variant={'outlined'}
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                  multiline
                  rows={4}
                />
              </>
            )}
          />
        </>
      </Grid>
    </Grid>
  );
};

const styles = {
  table: {
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'visible',
      lineHeight: '1rem',
      whiteSpace: 'normal',
      color: '#00285780'
    },
    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
      outline: 'none !important'
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none !important'
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#00285707',
      height: '55px',
      minHeight: '55px !important',
      fontFamily: 'NeoSansProMedium',
      maxHeight: '55px !important'
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none'
    },
    '& .odd': {
      backgroundColor: 'inherit'
    }
  },
  label: {
    paddingBottom: 0.5,
    color: 'secondary.dark',
    fontWeight: '300'
  }
};

export default TransportFacilityForm;
