export const table = {
  overflowX: 'visible',
  '& .MuiDataGrid-columnHeaderTitle': {
    overflow: 'visible',
    lineHeight: '1rem',
    whiteSpace: 'normal'
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '1px solid #0e2846',
    height: '55px',
    minHeight: '55px !important',
    fontFamily: 'NeoSansProMedium',
    maxHeight: '55px !important'
  },
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    boxShadow: 'none'
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: 'none !important'
  },
  '& .odd': {
    backgroundColor: 'background.default'
  },
  '& .secondary-text':{
    color: 'primary.dark'
  },
  '& .bold':{
    fontFamily: 'NeoSansProMedium'
  }
}
export default table;
