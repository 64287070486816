// @ts-nocheck
import {t} from 'ttag';
import React from 'react';
import {useCancelRefundMutation} from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';
import {Button} from "@mui/material";
import {CancelOutlined} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

const CancelRefund = ({ refundId }) => {
  const [cancelRefund, { data, error, isLoading }] = useCancelRefundMutation();

  return (
    <>
      <Button
        color='error'
        variant='contained'
        startIcon={<CancelOutlined/>}
        style={{ marginRight: 5 }}
        onClick={() => cancelRefund(refundId).then((r) => {
          if (!r.error) {
            enqueueSnackbar(t`Refund canceled successfully!`, { variant: 'success' });
          }
        })}
      >{t`CANCEL`}</Button>
      <RTKQueryFeedback error={error} isLoading={isLoading} />
    </>
  );
};

CancelRefund.propTypes = {};

CancelRefund.defaultProps = {};

export default CancelRefund;
