import React, { useEffect } from 'react';
import { useSnackbar, VariantOverrides } from 'notistack';
import LoadingScreen from './LoadingScreen';

interface RTKQueryFeedbackProps {
  data?: any,
  isLoading?: boolean,
  isSuccess?: boolean,
  error?: {
    data?: any,
    message: string
  }|null,
  successMessage?: string,
  snackbarOnSuccessOptions?: VariantOverrides
  snackbarOnErrorOptions?: VariantOverrides,
}

const RTKQueryFeedback = ({data = null, error = null, isLoading = false, isSuccess = false, successMessage = 'Success', snackbarOnSuccessOptions = {variant: 'success'}, snackbarOnErrorOptions = {variant: 'error'}}: RTKQueryFeedbackProps) => {

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (data || isSuccess) {
      enqueueSnackbar(successMessage, snackbarOnSuccessOptions);
    }
  }, [successMessage, data, isSuccess, enqueueSnackbar, snackbarOnSuccessOptions]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.data?.message || error.message, snackbarOnErrorOptions);
    }
  }, [error, enqueueSnackbar, snackbarOnErrorOptions]);

  return isLoading ? <LoadingScreen loading={isLoading} onClick={onclick}/> : null;
};

export default RTKQueryFeedback;
