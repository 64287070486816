// @ts-nocheck
import React, {forwardRef} from 'react';
import {MenuItem} from '@mui/material';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import Dropdown from '../../../components/common/Dropdown';

const RefundTypeSelectorField = forwardRef(({label, placeholder, name, onChange, value, error, required, disabled, helperText, fullWidth}, ref) => {

  return (
      <Dropdown
        ref={ref}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        error={error}
        onChange={onChange}
        required={required}
        helperText={helperText}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        <MenuItem key={0} value={'voluntary'}>{t`Voluntary`}</MenuItem>
        <MenuItem key={1} value={'involuntary'}>{t`Involuntary`}</MenuItem>
      </Dropdown>
  );
});

RefundTypeSelectorField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  helperText: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

RefundTypeSelectorField.defaultProps = {
  label: 'Currency',
  name: '',
  placeholder: '',
  value: '',
  error: false,
  onChange: () => null,
  required: false,
  helperText: '',
  disabled: false,
  fullWidth: true,
};

export default RefundTypeSelectorField;
