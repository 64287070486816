import {IClaim} from '../interfaces/claim';
import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
export const type = 'claim';
export const claimEndpoints = (builder:EndpointBuilder<any, any, any>) => ({
  // Get Claim Status Information
  getClaimStatus: builder.mutation<IClaim, {process: string}>({
    query: (process) => ({
      url: '/claims/status',
      method: 'POST',
      body: process
    }),
    invalidatesTags: [{type, id: 'list'}]
  })
});

export default claimEndpoints;
