import {t} from 'ttag';
import React from 'react';
import {Check} from '@mui/icons-material';
import {FormProvider, useForm} from 'react-hook-form';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import TransportFacilitiesConsumeForm from './transport-facilities-comsume-form/TransportFacilitiesConsumeForm';
import RTKQueryFeedback from '../../../../../components/common/RTKQueryFeedback';
import {IAuthorization} from '../../../../api/transportFacilityEndpoints.types';
import {useConsumeAuthorizationMutation} from '../../../../api';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {enqueueSnackbar} from 'notistack';

export const TransportFacilitiesConsume = ({
  data,
  fullScreen = false,
  onClose
}: {
  data: any;
  onClose: () => void;
  fullScreen: boolean;
}) => {
  const [onConsume, {data: dataConsume, error, isLoading}] = useConsumeAuthorizationMutation();

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        pnr: Yup.string()
          .nullable()
          .transform((_, value) => {
            return value === '' ? null : value;
          })
          .matches(/^[A-Z0-9]{6}$/, 'Is not in correct format. Exp: XXXXXX'),
        ticket: Yup.string()
          .nullable()
          .transform((_, value) => {
            return value === '' ? null : value;
          })
          .matches(/^\d{3}-\d{10}$/, 'Is not in correct format. Exp:xxx-xxxxxxxxxx'),
        isUsed: Yup.boolean().required(t`This field is required`)
      })
    ),
    mode: 'onChange',
    defaultValues: {
      ...(data as IAuthorization),
      pnr: data?.pnr || null,
      ticket: data?.ticket || null,
      isUsed: false
    }
  });

  const handleSave = methods.handleSubmit(
    (values) => {
      onConsume({
        ...data,
        pnr: values?.pnr || null,
        ticket: values?.ticket || null,
        state: !values?.isUsed ? 'ISSUED' : 'USED'
      }).then((e: {data?: IAuthorization; error?: any}) => {
        if (!e?.error) {
          onClose();
          enqueueSnackbar(t`Transport Facility consumed successfully!`, {variant: 'success'});
        };
      });
    },
    (values) => {
      console.error(values);
    }
  );

  return (
    <>
      <Dialog fullScreen={fullScreen} onClose={onClose} open>
        <DialogTitle color={'secondary'} variant={'h4'}>
          {'Consume Transport Facility'}
        </DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <TransportFacilitiesConsumeForm />
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button variant={'outlined'} color={'inherit'} onClick={onClose} autoFocus>
            {t`Cancel`}
          </Button>
          <Button
            startIcon={<Check />}
            onClick={handleSave}
            variant={'contained'}
            color={'primary'}
            autoFocus
          >{t`Save`}</Button>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback
        data={dataConsume}
        error={error}
        isLoading={isLoading}
        successMessage={t`Transport Facility saved with success!`}
      />
    </>
  );
};

export default TransportFacilitiesConsume;
