import React from 'react';
import {ResidentValidator} from "./index";
import ResidentValidatorTermsGuard from './ResidentValidatorTermsGuard';
import {Box} from "@mui/material";

const ResidentValidatorPage = () => (
    <Box>
      <ResidentValidatorTermsGuard>
        <ResidentValidator/>
      </ResidentValidatorTermsGuard>
    </Box>
);

export default ResidentValidatorPage;
