// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {Home} from '@mui/icons-material';
import {useMatch, useNavigate} from 'react-router-dom';
import {ListItem, ListItemIcon, ListItemText, useMediaQuery} from '@mui/material';
import { useStore } from "../../store/valtio";
import store from '../../store/redux/store';
import {useTheme} from "@mui/material";
const SidebarLink = ({path = '/', icon = <Home/>, text ='Link', itemKey, exact=false}) => {
  const {app} = useStore();
  const navigate = useNavigate();
  const matches = useMatch({path, end: exact});
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const clickHandler = () => {
    navigate(path);
    if (!isDesktop) {
      store.sideBarOpen = false
    }
  };

  return (
    <ListItem key={itemKey} onClick={clickHandler} sx={matches ? styles.activeButton : styles.button} >
      <ListItemIcon sx={styles.icon} children={icon} />
      <ListItemText primary={app.sideBarOpen ? text : text[0]} />
    </ListItem>
  );
};

const styles = {
  icon: {
    color: theme => theme.palette.secondary.contrastText,
    minWidth: 'unset',
    width: '1.5rem',
    marginRight: theme => theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  activeButton: {
    cursor: 'pointer',
    backgroundColor: theme => theme.palette.secondary.light,
    borderLeft: '0.250rem solid',
    // borderStyle: 'solid',
    borderLeftColor: theme => theme.palette.primary.main,
    paddingLeft: theme => theme.spacing(1) * 1.5,
    '&:hover': {
      backgroundColor: theme => theme.palette.secondary.light
    }
  },
  button: {
    cursor: 'pointer',
  }
};

SidebarLink.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
  icon: PropTypes.element,
  text: PropTypes.string,
  itemKey: PropTypes.any,
};
export default SidebarLink;
