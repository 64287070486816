// @ts-nocheck
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {Alert} from "@mui/material";

const ErrorsFeedback = ({errors, hideTitle}) => {

  const parseErrors = useCallback((error, title = '', key = '') => {
    if (!error) return null;

      if (typeof error === 'object') return Object.entries(error).map(([k, e], idx) => {
        if (typeof e === 'object' && e.hasOwnProperty('message')) {
          return parseErrors(e.message, k, idx);
        }
        return parseErrors(e, k, idx);
      });
    if (Array.isArray(error)) return error.map((e, idx) => parseErrors(e, '[ArrayElement]', idx));
    const titleTxt = !hideTitle ? `${title}: ` : '';
    return <Alert key={key} severity={'error'}>{titleTxt}{error}</Alert>;
  }, [hideTitle]);

  if (!Object.keys(errors).length) return null;

  return (
    <Box p={1}>
      {parseErrors(errors)}
    </Box>
  );
};

ErrorsFeedback.propTypes = {
  errors: PropTypes.shape({}),
  hideTitle: PropTypes.bool,
};

ErrorsFeedback.defultProps = {
  errors: null,
  hideTitle: false,
}

export default ErrorsFeedback;
