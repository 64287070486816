import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {giveRowAUniqueId} from "../../utils/table/table-utils";
import {IListParams} from "./utils/utils.types";
import {
  IProfessionalCategoriesList,
  IProfessionalCategory,
  IProfessionalCategoryDTO
} from "./professionalCategoryEndpoints.types";
import {API_ENDPOINT_TRANSPORT} from "../../config/settings";

export const type = 'professional';

export const professionalCategoryEndpoints = (builder:EndpointBuilder<any, any, any>) => ({
  getProfessionalCategories: builder.query<IProfessionalCategoriesList, IListParams>({
    query: (params) => ({
      url: `${API_ENDPOINT_TRANSPORT}/professional-categories`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    providesTags: (result, error, params) => [{type, id: 'getProfessionalCategories'}]
  }),
  getEmployeeSubGroups: builder.query<any, IListParams>({
    query: (params) => ({
      url: `${API_ENDPOINT_TRANSPORT}/employee-subgroups?page=0&size=999`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    providesTags: (result, error, params) => [{type, id: 'getEmployeeSubGroups'}]
  }),
  getProfessionalCategory: builder.query<IProfessionalCategory, {id: string}>({
    query: ({id}) => ({
      url: `${API_ENDPOINT_TRANSPORT}/professional-categories/${id}`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data,
  }),
  putProfessionalCategory: builder.mutation<void, IProfessionalCategoryDTO>({
    query: (body) => ({
      url: `${API_ENDPOINT_TRANSPORT}/professional-categories`,
      method: 'PUT',
      body
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'getProfessionalCategories'}]
  }),
  removeProfessionalCategory: builder.mutation<void, {id: string}>({
    query: ({id}) => ({
      url: `${API_ENDPOINT_TRANSPORT}/professional-categories/${id}`,
      method: 'DELETE'
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'getProfessionalCategories'}]
  }),
});

export default professionalCategoryEndpoints;
