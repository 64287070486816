import {configureStore, Middleware} from '@reduxjs/toolkit';
import sliceTemplate from './slices/sliceTemplate';
import api from "../apis/ccs";
import {ENABLE_REDUX_DEV_TOOLS} from "../../config/settings";
import ccsApi from "../apis/ccs";
import transactionsApi from "../../transport-facilities/api";

const store = configureStore({
  reducer: {
    sliceTemplate,
    [api.reducerPath]: api.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer
  },
  devTools: Boolean(ENABLE_REDUX_DEV_TOOLS),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
      ccsApi.middleware as Middleware).concat(transactionsApi.middleware as Middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

