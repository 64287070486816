import {t} from "ttag";
import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import {FieldError} from "react-hook-form";

interface IGenderSelector {
    error: FieldError | undefined;
    value: string;
    onChange: (...event: any[]) => void;
}

const GenderSelector = forwardRef(({error, value, onChange}: IGenderSelector, ref) => {

    const handleChange = (event: any) => {
        onChange(event.target.value);
    };

    return (
        <FormControl
            color={'primary'}
            variant={'outlined'}
            sx={{ minWidth: 120 }}
            size="small"
        >
            <InputLabel id="demo-simple-select-label">{t`Gender`}</InputLabel>
            <Select
                error={Boolean(error)}
                value={value}
                label={t`Gender`}
                color={'primary'}
                onChange={handleChange}
            >
                <MenuItem value={''}>{'-'}</MenuItem>
                <MenuItem value={'F'}>{t`Feminine`}</MenuItem>
                <MenuItem value={'M'}>{t`Masculine`}</MenuItem>
            </Select>
            <FormHelperText error={Boolean(error)} required>{error?.message}</FormHelperText>
        </FormControl>
    );
});

GenderSelector.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

GenderSelector.defaultProps = {
    value: '',
    onChange: () => null,
};

export default GenderSelector;
