import React, {useState} from 'react';
import {FormControl, InputAdornment, OutlinedInput} from "@mui/material";
import {Search} from "@mui/icons-material";
import {useDebouncedCallback} from 'use-debounce';

type PatternSearchProps = {
  placeholder: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  fullWidth?: boolean
}

export const PatternSearch = ({placeholder, value, onChange, fullWidth}: PatternSearchProps) => {
  const [input, setInput] = useState<string>(value);

  const searchHandler = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  }, 500);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    searchHandler(e);
  };

  return (
    <FormControl sx={{minWidth: '25ch'}} fullWidth={fullWidth} variant="outlined">
      <OutlinedInput
        sx={styles.textField}
        value={input}
        onChange={handleInputChange}
        id="search-pattern-input"
        type={'text'}
        placeholder={placeholder}
        size={'small'}
        endAdornment={
          <InputAdornment position="end">
            <Search/>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

const styles = {
  textField: {
    paddingRight: 0,
    '& .MuiOutlinedInput-root': {
      paddingRight: 0,
    },
    '& .MuiInputAdornment-root': {
      borderLeft: 'solid 1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      backgroundColor: '#F9FAFC',
      paddingX: ' 14px',
      paddingY: ' 7px'
    },
  }
}

export default PatternSearch;
