// @ts-nocheck
import React, {useState} from 'react';
import {t} from "ttag";
import PropTypes from 'prop-types';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {useGetUsersQuery} from '../../../store/apis/ccs';
import {columns} from './employeesListHelper';
import {DataGrid} from "@mui/x-data-grid";
import PatternSearch from "../../../components/pattern-search/PatternSearch";
import {Box} from "@mui/material";
import {table} from "../../../components/common/styles";

const EmployeesList = ({ removeHandler, editHandler }) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterPattern, setFilterPattern] = useState(null);
  const { data, error, isFetching } = useGetUsersQuery({page, pattern: filterPattern});

  return (
    <>
      <RTKQueryFeedback error={error} />
      <Box sx={{mb: 3}}>
        <PatternSearch
          fullWidth={true}
          placeholder={t`Search`}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setFilterPattern(e.target.value);
          }}
        />
      </Box>
      <DataGrid
        pagination
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        paginationModel={{page: page - 1, pageSize: data?.pagination?.rowsPerPage || 12}}
        rowCount={data?.count || 0}
        onPaginationModelChange={(pagination) => {
          setPage(pagination.page + 1);
        }}
        paginationMode={'server'}
        getRowId={(row) => row.userId}
        loading={isFetching}
        columns={columns({ editHandler, removeHandler })}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
        sx={table}
      />
    </>
  );
};

EmployeesList.propTypes = {
  setOpenEdit: PropTypes.func,
  setOpenRemove: PropTypes.func
};

EmployeesList.defaultProps = {};

export default EmployeesList;
