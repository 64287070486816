// @ts-nocheck
import React from 'react';
import {Grid, MenuItem, TextField} from '@mui/material';
import {t} from 'ttag';
import Dropdown from '../../../../../components/common/Dropdown';
import FormStepperActions from '../common/stepper/FormStepperActions';
import {Controller} from 'react-hook-form';
import RecipientSelector from "./RecipientSelector";

const Step3Form = () => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <RecipientSelector />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.name'}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Name`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              required
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.email'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Email`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              required
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.documentType'}
          render={({field, fieldState: {error}}) => (
            <Dropdown
              {...field}
              label={t`Document type`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            >
              <MenuItem value={'Passport'}>{t`Passport`}</MenuItem>
              <MenuItem value={'Citizen Card'}>{t`Citizen Card`}</MenuItem>
            </Dropdown>
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.documentId'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Document id`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.nif'}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`NIF`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              required
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.contact'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Contact`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.address'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Address`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.postalCode'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Postal code`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'recipient.iban'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`IBAN`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={12} item>
        <FormStepperActions/>
      </Grid>
    </Grid>
  );
};

export default Step3Form;
