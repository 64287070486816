// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import { useDeleteUserMutation } from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {Cancel} from "@mui/icons-material";

const DeleteEmployeeDialog = ({ open, onClose, employee }) => {

  const [deleteUser, { data, error, isLoading }] =
    useDeleteUserMutation();

  const handleSave = () => {
    deleteUser(employee).then((r) => {
      if (!r.error) {
        enqueueSnackbar(t`The employee` + ` ${employee?.employeeId} ` + t`was successfully removed!`, {variant: 'success'});
        onClose();
    }});
  };

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
        fullWidth
        maxWidth={'xs'}
      />
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Cancel color={'error'}/>
          <Typography variant='h2' color={'error'} sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Remove Employee`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t`Do you really want to remove this employee?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box display='flex' mx={1} gap={2}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
            <Button variant='contained' color='error' onClick={handleSave}>
              {t`Delete`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteEmployeeDialog.propTypes = {
  employee: PropTypes.shape({
    employeeId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

DeleteEmployeeDialog.defaultProps = {
  open: true
};

export default DeleteEmployeeDialog;
