// @ts-nocheck
import React, { useState } from 'react';
import { useGetRolesQuery } from '../../../store/apis/ccs';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import { columns } from './rolesListHelper';
import {DataGrid} from "@mui/x-data-grid";
import table from "../../../components/common/styles";

const RolesList = ({ editHandler, deleteHandler }) => {
  const [page, setPage] = useState(1);
  const { data = [], error, isFetching } = useGetRolesQuery(page);

  return (
    <>
      <RTKQueryFeedback error={error} />
      <DataGrid
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        pagination
        paginationModel={{page: page - 1, pageSize: data?.pagination?.rowsPerPage || 12}}
        rowCount={data?.count || 0}
        onPaginationModelChange={(pagination) => {
          setPage(pagination.page + 1);
        }}
        paginationMode="server"
        getRowId={(row) => row.roleId}
        loading={isFetching}
        columns={columns({ editHandler, removeHandler: deleteHandler })}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
        sx={table}
      />
    </>
  );
};

RolesList.propTypes = {};

RolesList.defaultProps = {};

export default RolesList;
