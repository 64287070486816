// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {useFormik} from 'formik';
import IssueOfficeForm from './IssueOfficeForm';
import {issueOfficeValidationSchema} from './helpers';
import {useCreateIssueOfficeMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

const CreateIssueOfficeFormDialog = ({issueOffice, open, onClose, ...rest}) => {

  const [createIssueOffice, {data, error, isLoading}] = useCreateIssueOfficeMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: issueOffice,
    validationSchema: issueOfficeValidationSchema,
    onSubmit: (values) => createIssueOffice(values).then(r => !r.error && onClose()),
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <DialogTitle>
        <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
          {t`Issue office`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <IssueOfficeForm formik={formik}/>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={.4}>
          <Button variant={'outlined'} onClick={onClose} m={3}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit} m={3}>{t`Create`}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CreateIssueOfficeFormDialog.propTypes = {
  issueOffice: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateIssueOfficeFormDialog.defaultProps = {
  issueOffice: null,
  open: true,
  onClose: null,
};

export default CreateIssueOfficeFormDialog;
