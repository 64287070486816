
const Amount = ({currency, value, defaultValue = '-'}: {currency: string, value: string|number, defaultValue?: string}): string => {
  let formattedAmount = defaultValue;

  if (value || typeof value === 'number') {
    formattedAmount = parseFloat(value as string).toFixed(2) || '';
  }

  if (currency) {
    formattedAmount = `${formattedAmount} ${currency} `;
  }

  return formattedAmount;
};

export default Amount;
