import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText, Skeleton,
  Typography
} from '@mui/material';
import {t} from 'ttag';
import {IClaim} from '../../../store/apis/interfaces/claim';

interface PassengerDetailsProps {
  data?: IClaim
}

export const PassengerDetails = ({data}: PassengerDetailsProps) => {

  if (!data) {
    return (
      <Card elevation={3}>
        <CardHeader
          title={<Typography sx={{fontFamily: 'NeoSansProMedium'}}>{t`Passenger Details`}</Typography>}
        />
        <Divider/>
        <CardContent>
          <List sx={{p: 0}}>
            <ListItem sx={{p: 0, maxHeight: '32px'}}>
              <ListItemText primary={t`Name`} />
              <ListItemText
                sx={{...styles.listItem, backgroundColor: 'none', mr: 1}}
                primary={<Skeleton width={'101%'} height={40}/>}
              />
            </ListItem>
            <ListItem sx={{p: 0, maxHeight: '32px'}}>
              <ListItemText primary={t`E-mail`} />
              <ListItemText
                sx={{...styles.listItem, backgroundColor: 'none', mr: 1}}
                primary={<Skeleton width={'101%'} height={40}/>}
              />
            </ListItem>
            <ListItem sx={{p: 0, maxHeight: '32px'}}>
              <ListItemText primary={t`Claim Status`} />
              <ListItemText
                sx={{...styles.listItem, backgroundColor: 'none', mr: 1}}
                primary={<Skeleton width={'101%'} height={40}/>}
              />
            </ListItem>
            <ListItem sx={{p: 0, maxHeight: '32px'}}>
              <ListItemText primary={t`Payment Status`} />
              <ListItemText
                sx={{...styles.listItem, backgroundColor: 'none', mr: 1}}
                primary={<Skeleton width={'101%'} height={40}/>}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography sx={{fontFamily: 'NeoSansProMedium'}}>{t`Passenger Details`}</Typography>}
      />
      <Divider/>
      <CardContent>
        <List sx={{p: 0}}>
          <ListItem sx={{p: 0}}>
            <ListItemText primary={t`Name`} />
            <ListItemText
              sx={styles.listItem}
              primary={data?.Contact?.firstName || data?.Contact?.lastName ? data?.Contact?.firstName + ' ' + data?.Contact?.lastName : <br/>}
            />
          </ListItem>
          <ListItem sx={{p: 0}}>
            <ListItemText primary={t`E-mail`} />
            <ListItemText
              sx={styles.listItem}
              primary={data?.Contact?.email || <br/>}
            />
          </ListItem>
          <ListItem sx={{p: 0}}>
            <ListItemText primary={t`Claim Status`} />
            <ListItemText
              sx={styles.listItem}
              primary={data?.Status ? data?.Status + ` (${data?.['Status Reason']})`: <br/>}
            />
          </ListItem>
          <ListItem sx={{p: 0}}>
            <ListItemText primary={t`Payment Status`} />
            <ListItemText
              sx={styles.listItem}
              primary={data?.PaymentStatus?.name || <br/>}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

const styles = {
  listItem: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    minWidth: '65%',
    pl: 1,
    maxWidth: '100px'
  }
}

export default PassengerDetails;
