import React from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Divider, Grid2, TextField, Typography} from '@mui/material';
import {t} from 'ttag';
import yup from '../../utils/yupExtensions';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useGetClaimStatusMutation} from '../../store/apis/ccs';
import {enqueueSnackbar} from 'notistack';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import {Search} from '@mui/icons-material';
import ClaimView from './ClaimView';
import {useSearchParams} from 'react-router-dom';

export interface ClaimSearchDTO {
  process: string;
}

const schema = yup.object().shape({
  process: yup.string().required(t`Process number is required`).matches(/^CAS-\d{6}-[A-Za-z0-9]{6}$/, t`The process number is not in the correct format`),
});

export const ClaimSearch = () => {
  const [onSubmitSearch, {data, isLoading}] = useGetClaimStatusMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm<ClaimSearchDTO>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      process: '',
    }
  });

  const handleSearch = methods.handleSubmit((data) => onSubmitSearch(data).then((e: any) => {
    if (e?.error) {
      enqueueSnackbar(e?.error?.message || t`Invalid process reference`, {variant: 'error'});
    }
    else {
      searchParams.set('process', data?.process);
      setSearchParams(searchParams);
    }
  }));

  if (searchParams.get('process')) {
    return (
      <ClaimView response={data} />
    )
  }

  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>{t`Claim Search`}</Typography>}
        subheader={<Typography color={'text.secondary'} variant={"subtitle2"}>{t`Search for a claim by its process number`}</Typography>}
      />
      <Divider/>
      <CardContent>
        <Grid2 container>
          <Grid2 size={{xs: 12}}>
            <Controller
              name="process"
              control={methods.control}
              render={({field, fieldState: {error}}) => (
                <TextField
                  {...field}
                  label={t`Process Number`}
                  placeholder={'Ex: CAS-611994-F4D5D9'}
                  required
                  fullWidth
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      document.getElementById('claim-search-button')?.click();
                    }
                  }}
                  error={!!error}
                  size={'small'}
                  helperText={error?.message}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^A-Za-z0-9-]/g, '').slice(0,17).toUpperCase();
                    const hyphenCount = value.split('-').length - 1;

                    if (hyphenCount < 2) {
                      if (value.startsWith('CAS') && !field.value?.startsWith('CAS-') && !value?.startsWith('CAS-')) {
                        value = 'CAS-' + value.slice(3);
                      }
                      if (value.length >= 10 && !field.value?.endsWith('-')) {
                        value = value.slice(0, 10) + '-';
                      }
                    }

                    field.onChange(value);
                  }}
                />
              )}/>
          </Grid2>
        </Grid2>
      </CardContent>
      <Divider/>
      <CardActions sx={{alignItems: 'center', justifyContent: 'right', p: 2}}>
        <Button
          onClick={handleSearch}
          disabled={isLoading}
          variant={'contained'}
          color={'primary'}
          startIcon={<Search/>}
          id={'claim-search-button'}
        >
          {t`Search`}
        </Button>
      </CardActions>
      <RTKQueryFeedback isLoading={isLoading}/>
    </Card>
  )
}

export default ClaimSearch;
