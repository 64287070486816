// @ts-nocheck
import React from "react";
import {t} from 'ttag';
import {Box, IconButton} from "@mui/material";
import {DeleteOutlined, EditOutlined} from "@mui/icons-material";

export const columns = ({editHandler, removeHandler}) => [
  {
    field: 'name',
    headerName: t`Name`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 175
  },
  {
    field: 'code',
    headerName: t`Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text',
    flex: 1,
    minWidth: 100
  },
  {
    field: 'amadeusCode',
    headerName: t`Amadeus code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text',
    flex: 1,
    minWidth: 125
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'actions',
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton onClick={() => editHandler(params.row)} color={'secondary'} aria-label='edit'>
            <EditOutlined fontSize='inherit' />
          </IconButton>
          <IconButton onClick={() => removeHandler(params.row)} color={'secondary'} aria-label='delete'>
            <DeleteOutlined fontSize='inherit'/>
          </IconButton>
        </Box>
      )
    }
  },
];

export const helper = {
  columns
};

export default helper;
