import * as React from 'react';
import {IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import {SnackbarKey, useSnackbar} from 'notistack';

function SnackbarCloseButton({snackbarKey}: { snackbarKey: SnackbarKey }) {
    const {closeSnackbar} = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <Close/>
        </IconButton>
    );
}

export default SnackbarCloseButton;