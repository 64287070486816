// @ts-nocheck
import React from 'react';
import { t } from 'ttag';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import RoleForm from './RoleForm';
import {
  useUpdateRoleMutation,
  useGetRoleQuery
} from '../../../../store/apis/ccs';
import { roleValidationSchema } from './helpers';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {enqueueSnackbar} from "notistack";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

const EditRoleDialog = ({ role: { roleId }, open, onClose }) => {
  const {
    data: role,
    error: rolesError,
    isLoading: rolesIsLoading
  } = useGetRoleQuery(roleId);
  const [editRole, { data, error, isLoading }] = useUpdateRoleMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: role,
    validationSchema: roleValidationSchema,
    onSubmit: (values) => editRole(values).then((r) => {
      if (!r.error) {
        enqueueSnackbar(t`The role` + ` ${r?.data?.name} ` + t`was successfully edited!`, {variant: 'success'});
        onClose();
      }})
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Edit Role`} {rolesIsLoading && t`Loading...`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <RoleForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={formik.handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback
        error={error || rolesError}
        isLoading={isLoading || rolesIsLoading}
        data={data}
      />
    </>
  );
};

EditRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditRoleDialog.defaultProps = {
  open: true
};

export default EditRoleDialog;
