// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import { useFormik } from 'formik';
import OfficeForm from './OfficeForm';
import {officeValidationSchema} from './officeFormValidationSchema';
import {useEditOfficeMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";

const EditOfficeFormDialog = ({ office, open, onClose, ...rest }) => {
  const [editOffice, { data, error, isLoading }] = useEditOfficeMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: office,
    validationSchema: officeValidationSchema,
    onSubmit: values => editOffice(values).then(r => {
      if (!r.error) {
        enqueueSnackbar(t`The office` + ` ${r?.data?.officeCode} ` + t`was successfully edited!`, {variant: 'success'});
        onClose();
      }})
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading} />
      <DialogTitle>
        <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
          {t`Edit Office`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={1}>
          <OfficeForm formik={formik} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={0.4}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={0.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit}>{t`Save`}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EditOfficeFormDialog.propTypes = {
  office: PropTypes.shape({
    officeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditOfficeFormDialog.defaultProps = {
  office: null,
  open: true,
  onClose: null
};

export default EditOfficeFormDialog;
