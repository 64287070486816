import React, {ReactElement} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {useMsal} from "@azure/msal-react";
import {InteractionStatus} from "@azure/msal-browser";
import store from "./store";

interface providerProps {
  children: ReactElement,
}

export const Provider = ({children} : providerProps) => {
  const {inProgress} = useMsal();
  if (inProgress === InteractionStatus.Startup) return null;
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};

export default Provider
