export const MSA_AUTHORITY = import.meta.env.VITE_MSA_AUTHORITY;
export const MSA_CLIENT_ID = import.meta.env.VITE_MSA_CLIENT_ID;

export const APP_NAME = import.meta.env.VITE_NAME;
export const APP_VERSION = import.meta.env.VITE_VERSION;

export const MSA_SCOPES_EMPLOYEE = import.meta.env.VITE_MSA_EMPLOYEE_PORTAL_SCOPES?.split(',');
export const AUDIENCE_EMPLOYEE_PORTAL = import.meta.env.VITE_EMPLOYEE_PORTAL_AUDIENCE;
// CCS - Contact Center Shell
export const AUDIENCE_CCS = import.meta.env.VITE_AUDIENCE_CCS;
export const MSA_SCOPES_CCS = import.meta.env.VITE_MSA_SCOPES_CCS?.split(',');
export const API_ENDPOINT_CCS = import.meta.env.VITE_API_ENDPOINT_CCS;

// TRANSPORT - Transport Facilities
export const AUDIENCE_TRANSPORT = import.meta.env.VITE_AUDIENCE_TRANSPORT;
export const MSA_SCOPES_TRANSPORT = import.meta.env.VITE_MSA_SCOPES_TRANSPORT;
export const API_ENDPOINT_TRANSPORT = import.meta.env.VITE_API_ENDPOINT_TRANSPORT;
export const MSA_SCOPES_AZURE = import.meta.env.VITE_MSA_SCOPES_AZURE;
export const ENABLE_REDUX_DEV_TOOLS = import.meta.env.VITE_ENABLE_REDUX_DEV_TOOLS;
export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT_CCS;
export const API_MOCK = import.meta.env.VITE_API_MOCK === 'true';
