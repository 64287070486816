// @ts-nocheck
import React from "react";
import {t} from 'ttag';
import Amount from '../Amount';
import CurrentValueTextField from '../../../../../../../../../common/CurrentValueTextField';

export const columns = ({editable, editHandler}: {editable: boolean, editHandler: () => void}) => [
  {
    field: 'type',
    headerName: t`Type`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'value',
    type: 'number',
    headerName: t`Value`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}: any) => {
      const {value, currency} = row || {};
      return <Amount value={value} currency={currency}/>;
    },
    cellClassName: 'bold'
  },
  {
    field: 'currentValue',
    type: 'number',
    headerName: t`Current value`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) => {
      const {currentValue: value, currency} = row || {};
      return <Amount value={value || 0} currency={currency}/>;
    },
    renderEditCell: (params: any) => {
      return (
        <CurrentValueTextField params={params} editHandler={editHandler}/>
      );
    },
    cellClassName: 'secondary-text bold'
  },
];
