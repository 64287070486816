import React from 'react';
import {AuthenticationGuard, AuthorizationGuard} from '../../guards';
import MainLayout from '../../layout/MainLayout';

interface pageProps {
  children: React.ReactNode;
    userHasSomeOf?: string[];
    userHasEveryOf?: string[];
    withMainLayout?: boolean;
    withAuthentication?: boolean;
}
export const Page = ({children = null, userHasSomeOf = [], userHasEveryOf = [], withMainLayout = true, withAuthentication = true} : pageProps) => {
  const GuardAuthentication = withAuthentication ? AuthenticationGuard : Default;
  const GuardAuthorization = userHasSomeOf.length || userHasEveryOf.length ? AuthorizationGuard : Default;
  const LayoutMain = withMainLayout ? MainLayout : Default;

  return (
    <GuardAuthentication>
      <GuardAuthorization
        userHasSomeOf={userHasSomeOf}
        userHasEveryOf={userHasEveryOf}
        showErrorView
        redirectToUnauthorized
      >
        <LayoutMain>{children}</LayoutMain>
      </GuardAuthorization>
    </GuardAuthentication>
  );
};

interface DefaultProps {
    children: any;
}
const Default = ({children}: DefaultProps) => children;

export default Page;
