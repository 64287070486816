// @ts-nocheck
import {t} from 'ttag';
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import {genCommands} from './refund-script/amadeus-scripts-utils';
import ScriptPreview from '../../../../../components/script-preview';
import {useGetFormsOfPaymentQuery} from '../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../components/common/RTKQueryFeedback';

const MaskRefundView = ({refund, isLoading}) => {
  const {data: formsOfPayment, error, isLoading: rtkIsLoading} = useGetFormsOfPaymentQuery();
  const loading = isLoading || rtkIsLoading;

  const commands = useMemo(() => {
    return refund && formsOfPayment?.data ? genCommands(refund, formsOfPayment?.data) : [];
  }, [refund, formsOfPayment]);

  return (
    <>
      <Card>
        <CardHeader
          title={<Typography sx={{fontFamily: 'NeoSansProMedium'}}>{t`Script`}</Typography>}
        />
        <Divider />
        <CardContent>
          <ScriptPreview commands={commands} isLoading={loading}/>
        </CardContent>
      </Card>
      <RTKQueryFeedback error={error} />
    </>
  );
};

MaskRefundView.propTypes = {
  refund: PropTypes.object,
  isLoading: PropTypes.bool
};

MaskRefundView.defaultProps = {
  refund: null,
  isLoading: false
};

export default MaskRefundView;
