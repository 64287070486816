import {createStore, resetStore} from './form-state-utils';
import {RefundSubject} from "../../../../../../types/RefundSubject.type";
export * from './form-state-utils';

export const defaultState = {
  step: 0,
  refundSubjectToImport: null,
  refundSubjectToEdit: null,
  refundSubjectToDelete: null,
  atcMaskVisible: false,
  form: {
    refundType: '',
    office: null,
    reason: '',
    refundSubjects: [],
    totalRefund: 0,
    modified: null,
    recipient: {},
  }
};

export const formState = createStore(defaultState);

// Actions
export const setStep = (step: number) => formState.step = step;
export const setFormData = (data: any) => formState.form = {...formState.form, ...data};
export const incStep = () => ++formState.step;
export const decStep = () => --formState.step;
export const setRefundSubjectToImport = (rs: RefundSubject) => formState.refundSubjectToImport = rs;
export const setRefundSubjectToEdit = (rs: RefundSubject) => formState.refundSubjectToEdit = rs;
export const setRefundSubjectToDelete = (rs: RefundSubject) => formState.refundSubjectToDelete = rs;
export const setAtcMaskVisible = (visible: boolean) => formState.atcMaskVisible = visible;
export const resetToDefaultState = () => resetStore(formState, defaultState);

export default formState;
