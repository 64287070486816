// @ts-nocheck
import { t } from 'ttag';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { columns } from './refundsListHelper';
import { useGetRefundsQuery } from '../../store/apis/ccs';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import DateRangePicker from '../../components/date-range-picker';
import './refunds-list.scss';
import {Box, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import PatternSearch from "../../components/pattern-search/PatternSearch";
import {createDatetime, DATE_FORMAT} from "../../utils/datetime/datetime-utils";
import table from "../../components/common/styles";
import {useSearchParams} from 'react-router-dom';

const RefundsList = ({ viewHandler, removeHandler }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState(searchParams.get('pattern') || '');
  const [filterPattern, setFilterPattern] = useState(null);
  const [filterRange, setFilterRange] = useState({
    startDate: searchParams.get('startDate') && DateTime.fromISO(searchParams.get('startDate') as string).isValid
      ? createDatetime(searchParams.get('startDate') as string, DATE_FORMAT).toJSDate()
      : DateTime.now().minus({days: 15}).toJSDate(),
    endDate: searchParams.get('endDate') && DateTime.fromISO(searchParams.get('endDate') as string).isValid
      ? createDatetime(searchParams.get('endDate') as string, DATE_FORMAT).toJSDate()
      : DateTime.now().toJSDate()
  });
  const [filterModified, setFilterModified] = useState(searchParams.get('modified') || false);

  const {
    data = [],
    error,
    isFetching
  } = useGetRefundsQuery({
    page,
    filterPattern,
    filterRange: {
      startDate: createDatetime(filterRange?.startDate?.toISOString()).toFormat(DATE_FORMAT),
      endDate: createDatetime(filterRange?.endDate?.toISOString()).plus({days: 1}).toFormat(DATE_FORMAT)
    },
    filterModified
  });

  const handleDateRangeChange = (v: {startDate: Date, endDate: Date}) => {
    searchParams.set('startDate', createDatetime(v.startDate.toISOString()).toFormat(DATE_FORMAT));
    searchParams.set('endDate', createDatetime(v.endDate.toISOString()).toFormat(DATE_FORMAT));
    setSearchParams(searchParams);
    setFilterRange(v);
  };

  return (
    <>
      <Grid container spacing={2} pb={2}>
        <Grid item xs={12} md={12} lg={6}>
          <PatternSearch
            fullWidth={true}
            placeholder={t`Search`}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setFilterPattern(e.target.value);
              e.target.value ? searchParams.set('pattern', e.target.value) : searchParams.delete('pattern');
              setSearchParams(searchParams);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Grid container spacing={2} justifyContent={{lg: 'flex-end', md: 'start'}}>
            <Grid item xs={12} md={6} lg={6} display={'flex'} justifyContent={'flex-end'}>
              <Box display={'flex'} width={{lg: '235px', md: '100%', xs: '100%'}}>
                <DateRangePicker size={'small'} range={filterRange} onChange={handleDateRangeChange} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl variant='outlined' style={{width: '100%'}}>
                <InputLabel id='demo-simple-select-outlined-label'>{t`Modified`}</InputLabel>
                <Select
                  labelId='filter-modified-selector'
                  id='filter-modified-selector'
                  value={filterModified}
                  size={'small'}
                  color={'primary'}
                  onChange={(e) => {
                    setFilterModified(e.target.value);
                    e.target.value ? searchParams.set('modified', e.target.value) : searchParams.delete('modified');
                    setSearchParams(searchParams);
                  }}
                  label={t`Modified`}
                  defaultValue={false}
                >
                  <MenuItem value={false}>{t`All`}</MenuItem>
                  <MenuItem value={'1'}>{t`True`}</MenuItem>
                  <MenuItem value={'0'}>{t`False`}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        autoHeight
        pagination
        autoPageSize
        page={page - 1}
        paginationModel={{page: page - 1, pageSize: data?.pagination?.rowsPerPage || 12}}
        rowCount={data?.count || 0}
        onPaginationModelChange={(pagination) => {
          setPage(pagination.page + 1);
        }}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode='server'
        getRowId={(row) => row.refundId}
        columns={columns({viewHandler, removeHandler})}
        rows={data?.data || []}
        loading={isFetching}
        onCellClick={({row, field}) => {
          if (field !== 'actions') {
            viewHandler(row);
          }
        }}
        disableRowSelectionOnClick
        disableExtendRowFullWidth={true}
        pageSize={data?.pagination?.rowsPerPage || 5}
        sx={table}
      />
      <RTKQueryFeedback error={error} />
    </>
  );
};

RefundsList.propTypes = {
  viewHandler: PropTypes.func.isRequired,
  removeHandler: PropTypes.func.isRequired
};

export default RefundsList;
