import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ReceiptDto} from "../../../scenes/receipt-request/ReceiptRequest";

export const type = 'receipts';
export const receiptEndpoints = (builder:EndpointBuilder<any, any, any>) => ({

  receiptRequest: builder.mutation<ReceiptDto, any>({
    query: (receipt) => ({
      url: '/receipts',
      method: 'POST',
      body: {...receipt,taxPayerNumber: parseInt(receipt?.taxPayerNumber), passengerTaxPayerNumber: receipt?.passengerTaxPayerNumber? parseInt( receipt?.passengerTaxPayerNumber ) : receipt?.taxPayerNumber, country: receipt?.country?.code?.toUpperCase()}
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

});
export default receiptEndpoints;

