import React from 'react';
import {CircularProgress, Dialog, DialogContent} from '@mui/material';

interface loadingScreenProps {
  loading: boolean,
  onClick: any,
}

export const LoadingScreen = ({loading = true, onClick = () => null, ...rest} : loadingScreenProps) => {

  if (!loading) return null;

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        },
      }}
      open={loading}
      onClick={onClick}
      {...rest}
    >
      <DialogContent>
        <CircularProgress color='inherit' />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingScreen;
