// @ts-nocheck
import {t} from 'ttag';
import React, {useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {Autocomplete, CircularProgress, TextField} from '@mui/material';
import RTKQueryFeedback from "../../../components/common/RTKQueryFeedback";
import {useGetUsersQuery} from "../../api";

export const EmployeeSelector = ({ value , onChange, includesDisabled = false}) => {
  const [pattern, setPattern] = useState(value?.name || '1000');
  const {data = [], error, isFetching} = useGetUsersQuery({searchPattern: pattern, includesDisabled});
  const [input, setInput] = useState('         ');

  const searchHandler = useDebouncedCallback((inputPattern) => {
    if (inputPattern.length >= 3) {
      setPattern(inputPattern.substring(0, inputPattern.indexOf(' -')).length >= 3 ? inputPattern.substring(0, inputPattern.indexOf(' -')) : inputPattern)
    }
  }, 300);

  const handleInputChange = (_, pattern) => {
    setInput(pattern);
    searchHandler(pattern);
  };

  if (!data) {
    return (
      <TextField
        sx={{backgroundColor: '#FFFFFF'}}
        placeholder={t`Loading...`}
        fullWidth
        size={'small'}
        variant={'outlined'}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <CircularProgress color='inherit' size={20}/>
          )
        }}
      />
    )
  }

  return (
    <>
      <RTKQueryFeedback error={error}/>
      <Autocomplete
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isFetching}
        disableClearable
        options={data}
        inputValue={input}
        size={'small'}
        renderTags={() => null}
        value={value}
        isOptionEqualToValue={(option, value) => option?.userId === value?.userId}
        getOptionLabel={(opt) => `${opt?.name} - ${opt?.employeeId}`}
        filterOptions={(opts, {inputValue = ''}) => {
          return opts.filter(o => `${o?.employeeId} ${o?.name}`.toLowerCase().includes(inputValue.toLowerCase()));
        }}
        onInputChange={handleInputChange}
        onChange={(_, user) => {
          onChange(data.find((u) => u?.employeeId === user?.employeeId));
          sessionStorage.setItem('selectedEmployeeId', JSON.stringify(data.find((u) => u?.employeeId === user?.employeeId)));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}
            placeholder={t`Start typing...`}
            onKeyDown={e => e.key === 'Backspace' && e.stopPropagation()}
            variant={'outlined'}
            SelectProps={{ IconComponent: () => null }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? <CircularProgress color='inherit' size={20}/> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </>
  );
}

export default EmployeeSelector;
