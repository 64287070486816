// @ts-nocheck
import {t} from 'ttag';
import Amount from '../crud-forms/create-edit/form/views/Amount';
import {IconButton, Box} from "@mui/material";
import {Check, Close, DeleteOutlined, Launch} from "@mui/icons-material";
import React from "react";

export const columns = ({viewHandler, deleteHandler, editable}) => [
  {
    field: 'companyCode',
    headerName: t`Company`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'documentNumber',
    headerName: t`Number`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    cellClassName: 'secondary-text'
  },
  {
    field: 'totalAmount',
    headerName: t`Amount`,
    type: 'number',
    renderCell: ({row}) => {
      const {currentValue, currency} = row.amounts?.find(a => a.type === 'totalAmount') || {};
      return <Amount value={currentValue} currency={currency}/>;
    },
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    cellClassName: 'bold'
  },
  {
    field: 'totalRefund',
    headerName: t`Refund`,
    type: 'number',
    renderCell: ({row}) => {
      const {currentValue, currency} = row.amounts?.find(a => a.type === 'totalRefund') || {};
      return <Amount value={currentValue} currency={currency}/>;
    },
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    cellClassName: 'bold'
  },
  {
    field: 'modified',
    headerName: t`Modified`,
    editable: false,
    sortable: false,
    type: 'boolean',
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => (
      row.modified ? <Check color={'success'}/> : <Close color={'error'}/>
    )
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'number',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton onClick={() => viewHandler(params.row)} size={'small'} aria-label='edit' color='primary'>
            <Launch fontSize='inherit'/>
          </IconButton>
          {editable && (
            <IconButton onClick={() => deleteHandler(params.row)} color={'secondary'} size={'small'} aria-label='delete'>
              <DeleteOutlined fontSize='inherit'/>
            </IconButton>
          )}
        </Box>
      )
    }
  },
];
