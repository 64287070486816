import {t} from 'ttag';
import * as Yup from 'yup';

export const beneficiaryValidationSchema = Yup.object().shape({
    name: Yup.string().min(3, t`Necessita de ter pelo menos 3 caracteres`).required(t`Este campo é obrigatório`),
    gender: Yup.string().test(
      'empty-check',
      '',
      gender => gender ? gender?.length > 0: false
    ),
    birthDate: Yup.string().required(t`Este campo é obrigatório`),
    idNumber: Yup.string().min(3, t`Necessita de ter pelo menos 3 caracteres`).required(t`Este campo é obrigatório`)
});
