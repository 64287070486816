import React from 'react';
import {SnackbarProvider as Provider} from 'notistack';
import SnackbarCloseButton from "./SnackbarCloseButton";

interface SnackbarProviderProps {
    children: React.ReactNode;
}

const SnackbarProvider = ({children, ...rest}: SnackbarProviderProps) => {

  return (
    <Provider
      style={{fontFamily: 'NeoSansProRegular'}}
      maxSnack={5}
      action={(key) => (<SnackbarCloseButton snackbarKey={key} />)}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      autoHideDuration={6000}
      dense
      {...rest}
    >
      {children}
    </Provider>
  );
};

export default SnackbarProvider;
