// @ts-nocheck
import React from 'react';
import { t } from 'ttag';
import PropTypes from 'prop-types';
import RolesSearch from '../../../../components/role-search';
import {FormControlLabel, Grid, Switch, TextField} from '@mui/material';

const EmployeeForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <TextField
          disabled
          label={t`Name`}
          name={'name'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('name', e.target.value)}
          value={formik.values?.name || ''}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          disabled
          label={t`Email`}
          name={'email'}
          color={'primary'}
          variant={'outlined'}
          onChange={(e) => formik.setFieldValue('email', e.target.value)}
          value={formik.values?.email || ''}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Cost Center`}
          name={'costCenter'}
          variant={'outlined'}
          color={'primary'}
          onChange={(e) => formik.setFieldValue('costCenter', e.target.value)}
          value={formik.values?.costCenter || ''}
          error={Boolean(formik.errors.costCenter)}
          helperText={formik.errors.costCenter || ''}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <RolesSearch
          value={formik.values?.roles}
          onChange={(roles) => formik.setFieldValue('roles', roles)}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <FormControlLabel
          control={
            <Switch
              name={'disabled'}
              // onChange={(e, value) => formik.setFieldValue(value)}
              onChange={(e) => {
                formik.setFieldValue('disabled', !e.target.checked);
              }}
              checked={!Boolean(formik.values?.disabled)}
            />
          }
          label={t`Active`}
        />
      </Grid>
    </Grid>
  );
};

EmployeeForm.propTypes = {
  formik: PropTypes.object
};

EmployeeForm.defaultProps = {
  formik: {}
};

export default EmployeeForm;
