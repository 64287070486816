import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {API_ENDPOINT_TRANSPORT} from "../../config/settings";
import {
  AllowanceListParams,
  IAllowanceDefinition,
  IAllowanceDefinitionDTO,
  IAllowanceDefinitions
} from "./allowanceSettingsEndpoints.types";
import {giveRowAUniqueId} from "../../utils/table/table-utils";
export const type = 'allowance-definitions';
export const allowanceDefinitionsEndpoints = (builder:EndpointBuilder<any, any, any>) => ({
  getAllowanceDefinitions: builder.query<IAllowanceDefinitions, AllowanceListParams>({
    query: (params) => ({
      url: `${API_ENDPOINT_TRANSPORT}/allowance-definitions`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    providesTags: (result, error, params) => [{type, id: 'getAllowanceDefinitions'}]
  }),
  getAllowanceDefinition: builder.query<IAllowanceDefinition, {id: string}>({
    query: ({id}) => ({
      url: `${API_ENDPOINT_TRANSPORT}/allowance-definitions/${id}`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data,
  }),
  putAllowanceDefinition: builder.mutation<IAllowanceDefinition, IAllowanceDefinitionDTO>({
    query: (body) => ({
      url: `${API_ENDPOINT_TRANSPORT}/allowance-definitions`,
      method: 'PUT',
      body
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'getAllowanceDefinitions'}]
  }),
  removeAllowanceDefinition: builder.mutation<void, {id: string}>({
    query: ({id}) => ({
      url: `${API_ENDPOINT_TRANSPORT}/allowance-definitions/${id}`,
      method: 'DELETE'
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'getAllowanceDefinitions'}]
  }),

});

export default allowanceDefinitionsEndpoints;
