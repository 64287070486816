// @ts-nocheck
import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './refundSubjectAmountsColumns';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import {useFormContext, useWatch} from 'react-hook-form';
import table from '../../../../../../../../../../../components/common/styles';

const RefundSubjectAmounts = ({editable}) => {

  const {setValue} = useFormContext();
  const [amounts, amountsOriginal] = useWatch({name: ['amounts', 'original.amounts']});

  const editHandler = ({id, field, value}) => {
    const original = amountsOriginal.find(({localId}) => localId === id);
    const modified = Boolean(!original || original?.[field] !== value);
    setValue('amounts', amounts.map(tx => tx.localId === id ? {...tx, [field]: value, modified} : tx), {shouldValidate: true});
  }

  return (
    <Card elevation={3}>
      <CardHeader title={<Typography fontFamily={'NeoSansProMedium'}>{t`Amounts`}</Typography>}/>
      <Divider/>
      <CardContent>
        <DataGrid
          autoHeight
          density={'compact'}
          getRowId={({localId}) => localId}
          columns={columns({editable, editHandler})}
          rows={amounts?.filter(a => ['totalFareUsed', 'totalCancellationFee'].includes(a.type)) || []}
          initialState={{
              pagination:{
                  paginationModel: { pageSize: 100 }
              }
          }}
          pageSizeOptions={[100]}
          disableRowSelectionOnClick
          hideFooter
          sx={table}
        />
      </CardContent>
    </Card>
  );
};

RefundSubjectAmounts.propTypes = {
  editable: PropTypes.bool,
};

RefundSubjectAmounts.defaultProps = {
  editable: true,
};

export default RefundSubjectAmounts;
