// @ts-nocheck
import React, {useState} from 'react';
import {columns} from './officesListHelper';
import {useGetOfficesQuery} from '../../../store/apis/ccs';
import PropTypes from 'prop-types';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {DataGrid} from "@mui/x-data-grid";
import PatternSearch from "../../../components/pattern-search/PatternSearch";
import {t} from "ttag";
import {Box} from "@mui/material";
import table from "../../../components/common/styles";

const OfficesList = ({editHandler, removeHandler}) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterPattern, setFilterPattern] = useState(null);
  const {data = [], error, isFetching} = useGetOfficesQuery({
    pattern: filterPattern,
    page
  });

  return (
    <>
      <RTKQueryFeedback error={error}/>
      <Box sx={{mb: 3}}>
        <PatternSearch
          fullWidth={true}
          placeholder={t`Search`}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setFilterPattern(e.target.value);
          }}
        />
      </Box>
      <DataGrid
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        pagination
        paginationModel={{page: page - 1, pageSize: data?.pagination?.rowsPerPage || 12}}
        rowCount={data?.count || 0}
        onPaginationModelChange={(pagination) => {
          setPage(pagination.page + 1);
        }}
        autoHeight
        getRowId={({officeId}) => officeId}
        columns={columns({editHandler, removeHandler})}
        rows={data?.data || []}
        pageSize={data?.pagination?.rowsPerPage || 5}
        loading={isFetching}
        disableSelectionOnClick
        sx={table}
      />
    </>

  );
};

OfficesList.propTypes = {
  editHandler: PropTypes.func.isRequired,
  removeHandler: PropTypes.func.isRequired,
};

export default OfficesList;
