import * as yup from 'yup';

// Custom NIF validation method
yup.addMethod(yup.string, 'nif', function (errorMessage) {
  return this.test('nif', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true; // Skip validation if value is empty

    const isValidNif = (nif: string ) => {
      if (nif.length !== 9 || !/^[0-9]{9}$/.test(nif) || nif === '123456789') {
        return false;
      }
      const validFirstDigits = [1, 2, 3, 5, 6, 8, 9];
      if (!validFirstDigits.includes(parseInt(nif[0], 10))) {
        return false;
      }
      let checkDigit = parseInt(nif[0], 10) * 9;
      for (let i = 2; i <= 8; i++) {
        checkDigit += parseInt(nif[i - 1], 10) * (10 - i);
      }
      checkDigit = 11 - (checkDigit % 11);
      if (checkDigit > 9) checkDigit = 0;
      return checkDigit === parseInt(nif[8], 10);
    };

    return isValidNif(value) || createError({ path, message: errorMessage });
  });
});

export default yup;
