// @ts-nocheck
import {t} from 'ttag';
import React from 'react';
import {Button} from "@mui/material";
import {useCloseRefundMutation} from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';
import {CheckCircleOutlined} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

const CloseRefund = ({ refundId }) => {
  const [closeRefund, { data, error, isLoading }] = useCloseRefundMutation();

  return (
    <>
      <Button
        color='success'
        variant='contained'
        startIcon={<CheckCircleOutlined/>}
        style={{ marginLeft: 5 }}
        onClick={() => {
          closeRefund(refundId).then((r) => {
            if (!r.error) {
              enqueueSnackbar(t`Refund closed successfully!`, { variant: 'success' });
            }
          });
        }}
      >{t`PROCESS`}</Button>
      <RTKQueryFeedback isLoading={isLoading} error={error} />
    </>
  );
};

CloseRefund.propTypes = {};

CloseRefund.defaultProps = {};

export default CloseRefund;
