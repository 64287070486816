import React, {useMemo} from 'react';
import {MenuItem, TextField} from '@mui/material';
import {TransportFacilitiesYearsSelectorTypes} from './TransportFacilitiesYearsSelector.types';

export const TransportFacilitiesYearsSelector = ({
  value,
  onChange,
  minYear,
  maxYear,
  sx
}: TransportFacilitiesYearsSelectorTypes) => {
  const yearsList = useMemo(() => {
    let years = [] as number[];
    for (let i = parseInt(maxYear); i > parseInt(minYear); i--) {
      years.push(i);
    }
    return years;
  }, [minYear, maxYear]);

  return (
    <TextField sx={sx} value={value} onChange={(e) => onChange(e.target.value)} size={'small'} select fullWidth>
      {yearsList.map((r, idx) => (
        <MenuItem key={idx} value={r}>
          {r}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default TransportFacilitiesYearsSelector;
