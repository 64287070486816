import axios, {AxiosRequestHeaders} from 'axios';
import msalInstance from '../../../utils/msal-instance';
import {retrieveCurrentTokenPerScope} from "sata-auth";
import {API_ENDPOINT_TRANSPORT, MSA_SCOPES_TRANSPORT} from "../../../config/settings";

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT_TRANSPORT
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem('accessToken_transport') || null;

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`
      } as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (!originalRequest._retry) {
      try {
        originalRequest._retry = true;
        const token = await retrieveCurrentTokenPerScope(msalInstance, MSA_SCOPES_TRANSPORT || '');
        if (token) {
          localStorage.setItem('accessToken_transport', token);
        }
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      } catch (error) {
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);


export {axiosInstance};

export default axiosInstance;
