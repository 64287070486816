// @ts-nocheck
import { t } from 'ttag';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Done } from '@mui/icons-material';
import ScriptPreviewSkeleton from './ScriptPreviewSketelon';
import {Box, IconButton, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";

const ScriptPreview = ({ title, commands, isLoading }) => {
  const [smartFlowMode, setSmartFlowMode] = useState('false');
  const [anchorEl, setAnchorEl] = useState(null);

  const commandsStr = useMemo(() => {
    return commands
      ?.map((c) => (smartFlowMode === 'true' ? `send "${c}"` : c))
      .join('\n');
  }, [commands, smartFlowMode]);

  if (isLoading) return <ScriptPreviewSkeleton />;

  const open = Boolean(anchorEl);
  const copyToClipboard = () => navigator.clipboard.writeText(commandsStr);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    copyToClipboard().then((r) => setTimeout(() => setAnchorEl(null), 2000));
  };

  return (
    <>
      {title && (
        <Typography align={'center'}>
          <b>{title}</b>
        </Typography>
      )}
      <Box sx={styles.commandContainer}>
        <IconButton size='small' style={styles.clipboard} onClick={handleClick}>
          {open ? <Done /> : <FileCopyIcon />}
        </IconButton>
        <pre>{commandsStr}</pre>
      </Box>
      <ToggleButtonGroup
        value={smartFlowMode}
        exclusive
        onChange={(e, newValue) => {
          if (!newValue) {
            return false;
          }
          setSmartFlowMode(newValue);
        }}
        aria-label='text alignment'
        style={{ width: '100%' }}
      >
        <ToggleButton
          style={{ width: '100%' }}
          value='false'
          aria-label='Commands'
        >
          {t`Commands`}
        </ToggleButton>
        <ToggleButton
          style={{ width: '100%' }}
          value='true'
          aria-label='Smart Flow'
        >
          {t`Smart Flow`}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

const styles = {
  commandContainer: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '5px',
    padding: '5px 15px',
    fontSize: '100%',
    minHeight: 130,
    position: 'relative'
  },
  clipboard: { color: 'white', position: 'absolute', right: 10, top: 10 }
};

ScriptPreview.propTypes = {
  title: PropTypes.string,
  commands: PropTypes.array
};

ScriptPreview.defaultProps = {
  title: '',
  commands: []
};

export default ScriptPreview;
