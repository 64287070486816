// @ts-nocheck
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {useFormik} from 'formik';
import OfficeForm from './OfficeForm';
import {officeValidationSchema} from './officeFormValidationSchema';
import {useCreateOfficeMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {Warning} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

const CreateOfficeFormDialog = ({office, open, onClose, ...rest}) => {
  const [officeAlert, setOfficeAlert] = useState(false);
  const [createOffice, {data, error, isLoading}] = useCreateOfficeMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: office,
    validationSchema: officeValidationSchema,
    onSubmit: (values) => {
      if (officeAlert) {
        createOffice(values).then((r) => {
          if (!r.error) {
            setOfficeAlert(false);
            enqueueSnackbar(t`The office` + ` ${r?.data?.officeCode} ` + t`was successfully created!`, {variant: 'success'});
            onClose();
          }
        });
      } else {
        setOfficeAlert(true);
      }
    }
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading} />
      <DialogTitle>
        <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
          {t`New Office`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <OfficeForm formik={formik} />
      </DialogContent>
      <DialogActions>
        <Box p={1} px={0.4}>
          <Button variant={'outlined'} onClick={onClose} m={3}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={0.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit} m={3}>{t`Create`}</Button>
        </Box>
      </DialogActions>
      <Dialog
        open={officeAlert}
      >
        <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Warning color={'warning'}/>
          <Typography variant='h2' color={'warning.main'} sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Warning`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t`This action is only effective if the office entered is already previously configured.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant={'outlined'} onClick={() => setOfficeAlert(false)}>{t`Cancel`}</Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant={'contained'} color={'warning'} onClick={formik.handleSubmit}>{t`Create`}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

CreateOfficeFormDialog.propTypes = {
  office: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

CreateOfficeFormDialog.defaultProps = {
  office: null,
  open: true,
  onClose: null
};

export default CreateOfficeFormDialog;
