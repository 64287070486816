// @ts-nocheck
import React, {forwardRef} from 'react';
import {MenuItem} from '@mui/material';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {useGetOfficesQuery} from '../../../store/apis/ccs';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import Dropdown from '../../../components/common/Dropdown';

const OfficeSelectorField = forwardRef(({label, name, onChange, value, error, helperText, ...props}, ref) => {

  const {data = [], error: getOfficesError, isLoading} = useGetOfficesQuery();

  return (
    <>
      <RTKQueryFeedback error={getOfficesError}/>
      <Dropdown
        ref={ref}
        name={name}
        label={label}
        error={error}
        value={value?.officeCode || ''}
        onChange={(e) => onChange(data?.data?.find((office) => office?.officeCode === e.target.value))}
        helperText={helperText}
        fullWidth
        select
        {...props}
      >
        {isLoading && <MenuItem disabled>{t`Loading...`}</MenuItem>}
        {data?.data?.map((o, idx) => (
          <MenuItem key={idx} value={o.officeCode}>{o?.description || o?.currencyCode}</MenuItem>
        ))}
      </Dropdown>
    </>
  );
});

OfficeSelectorField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  hiddenLabel: PropTypes.bool,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.node,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(['medium', 'small']),
  type: PropTypes.string,
  value: PropTypes.shape({
    officeCode: PropTypes.string,
  }),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

OfficeSelectorField.defaultProps = {
  label: 'Currency',
  onChange: () => null,
  value: null,
  error: false,
  helperText: ''
};

export default OfficeSelectorField;
