// @ts-nocheck
import {t} from 'ttag';
import {Delete} from '@mui/icons-material';
import {IconButton, TextField} from '@mui/material';
import Amount from '../Amount';
import {Box} from "@mui/material";
import CurrentValueTextField from '../../../../../../../../../common/CurrentValueTextField';

export const columns = ({
  removeHandler,
  editHandler,
  editable,
  disableDeleteBtn = false
}) => [
  {
    field: 'taxCode',
    headerName: t`Code`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    cellClassName: 'bold secondary-text',
    valueGetter: (params) => (params.row.taxCode || 'XX').toUpperCase(),
    renderEditCell: (params) => {
      return (
        <TextField
          variant='standard'
          size={'small'}
          value={params.value}
          type={'text'}
          InputProps={{
            disableUnderline: true
          }}
          inputProps={{maxLength: 2, style: {color: 'rgba(0, 85, 138, 1)'}}}
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '0.875rem',
              pl: 1.15,
              pt: 0.7,
            },
          }}
          autoFocus
          fullWidth
          onChange={(e) => {
            const updatedValue = e.target.value?.toUpperCase();
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: updatedValue
            });
            editHandler({...params, value: updatedValue.length > 0 ? updatedValue : 'XX'});
          }}
        />
      );
    },
  },
  {
    field: 'value',
    type: 'number',
    headerName: t`Value`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => (
      <Amount value={row.value} currency={row.currency} />
    ),
    cellClassName: 'bold'
  },
  {
    field: 'currentValue',
    type: 'number',
    headerName: t`Current value`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => (
      <Amount value={row.currentValue || 0} currency={row.currency} />
    ),
    cellClassName: 'secondary-text bold',
    renderEditCell: (params) => {
      return (
        <CurrentValueTextField params={params} editHandler={editHandler}/>
      )
    }
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    hide: !editable,
    type: 'number',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton
            onClick={() => removeHandler(params.row)}
            disabled={disableDeleteBtn}
            size={'small'}
            aria-label='delete'
          >
            <Delete fontSize='inherit' />
          </IconButton>
        </Box>
      );
    }
  }
];
