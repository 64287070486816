import {useLocation, useNavigate} from 'react-router-dom';
import React, {ReactElement, useEffect} from 'react';
import {useAccount, useIsAuthenticated} from "@azure/msal-react";

type AuthenticationGuardProps = {
    children: ReactElement;
}

export const AuthenticationGuard = ({children}: AuthenticationGuardProps) => {
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount();
    const {pathname, search} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated && !['/auth', '/logout', '/401', '/403'].includes(pathname) && !account) {
            window.localStorage.setItem('_redirect_', pathname);
            window.localStorage.setItem('_params_', search);
            navigate('/auth');
        }
    }, [isAuthenticated, pathname, account, navigate, search]);

    if (!isAuthenticated) return null;

    return <>{children}</>;
};

export default AuthenticationGuard;
