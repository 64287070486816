// @ts-nocheck
import {t} from "ttag";
import {DateTime} from 'luxon';

const useCalendarShortcuts = () => {
    return [
        {
            label: t`Today`,
            startDate: new Date(),
            endDate: new Date()
        },
        {
            label: t`This week`,
            startDate: DateTime.now().startOf("week").toJSDate(),
            endDate: DateTime.now().endOf("week").toJSDate()
        },
        {
            label: t`This month`,
            startDate: DateTime.now().startOf("month").toJSDate(),
            endDate: DateTime.now().endOf("month").toJSDate()
        },
        {
            label: t`Yesterday`,
            startDate:DateTime.now().minus({day: 1}).toJSDate(),
            endDate:DateTime.now().minus({day: 1}).toJSDate()
        },
        {
            label: t`Last week`,
            startDate:DateTime.now().minus({week: 1}).startOf("week").toJSDate(),
            endDate:DateTime.now().minus({week: 1}).endOf("week").toJSDate()
        },
        {
            label: t`Last month`,
            startDate:DateTime.now().minus({month: 1}).startOf("month").toJSDate(),
            endDate:DateTime.now().minus({month: 1}).endOf("month").toJSDate()
        },
        {
            label: t`Last 15 days`,
            startDate: DateTime.now().minus({days: 15}).toJSDate(),
            endDate: DateTime.now().toJSDate()
        },
    ];
}

export default useCalendarShortcuts;