import React, {ReactElement} from 'react';
import {MsalProvider} from "@azure/msal-react";
import msalInstance from "../utils/msal-instance";

type AzureAuthProviderProps = {
  children: ReactElement
}

export const AzureAuthProvider = ({children}: AzureAuthProviderProps) => {
  return (
      <MsalProvider instance={msalInstance}>
        {children}
      </MsalProvider>
  );
};

export default AzureAuthProvider;
