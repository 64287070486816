// @ts-nocheck
import {t} from 'ttag';
import React from 'react';
import {Chip, Tooltip, Typography} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {createDatetime as dt} from "../../../../../utils/datetime/datetime-utils"
import {GridActionsCellItemProps} from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import {DateTime} from "luxon";

export const columns = ({onClickConsume, onClickCancel, onClickRefund}) => [
  {
    flex: 1,
    minWidth: 180,
    field: 'name',
    headerName: t`Beneficiary`,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({row}) => {
      const arrayName = row?.familyMember?.name?.split(" ");
      const name = arrayName?.[0] + ' ' + arrayName[arrayName.length - 1];
      return <Tooltip title={row?.familyMember?.name}><Typography variant={'body2'} fontFamily={'NeoSansProMedium'} color={'secondary.dark'}>{name}</Typography></Tooltip>
    }
  },
  {
    flex: 1,
    minWidth: 110,
    field: 'kinship',
    headerName: t`Relationship`,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({row}) => <Typography variant={'body2'} color={'accordion.label'} sx={{textTransform: 'capitalize'}} fontFamily={'NeoSansProRegular'}>{row?.beneficiary?.relationshipType.toLowerCase()}</Typography>
  },
  {
    flex: 1,
    minWidth: 130,
    field: 'citizenCard',
    headerName: t`National ID Card`,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({row}) => <Typography variant={'body2'} color={'accordion.label'} fontFamily={'NeoSansProRegular'}>{row?.familyMember?.idNumber}</Typography>
  },
  {
    flex: 1,
    minWidth: 150,
    field: 'facilityType',
    headerName: t`Discount type`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => <Typography variant={'body2'} color={'secondary.dark'} fontFamily={'NeoSansProMedium'}>{row?.facilityType?.designation}</Typography>
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'travelDate',
    headerName: t`Trip start`,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({row}) => <Typography variant={'body2'} color={'accordion.label'} fontFamily={'NeoSansProRegular'}>{dt(row?.request?.travelDate).toLocaleString(DateTime.DATE_MED)}</Typography>
  },
  {
    minWidth: 200,
    field: 'travelToCompany',
    headerName: t`Traveling company`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => <Typography variant={'body2'} color={'accordion.label'} fontFamily={'NeoSansProRegular'}>{row.request?.travelCompany?.name}</Typography>
  },
  {
    flex: 1,
    minWidth: 225,
    field: 'autorization',
    headerName: t`Autorization`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => <Typography variant={'body2'} color={'primary.main'} fontFamily={'NeoSansProRegular'}>{row.number}</Typography>
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'PNR',
    headerName: t`PNR`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => <Typography variant={'body2'} fontFamily={'NeoSansProRegular'}>{row?.pnr || '-'}</Typography>
  },
  {
    flex: 1,
    minWidth: 150,
    field: 'TKT',
    headerName: t`Ticket`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => <Typography variant={'body2'} fontFamily={'NeoSansProRegular'}>{row?.ticket || '-'}</Typography>
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'reservationType',
    headerName: t`Status`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => <Chip sx={{backgroundColor: '#7F93AB', color: 'white', width: '75px', pt: 0.5, textTransform: 'capitalize'}} size='small' label={row.state.toLowerCase()}/>
  },
  {
    field: 'actions',
    type: 'actions',
    disableColumnMenu: true,
    sortable: false,
    getActions: ({row}) => {
      let actions = [] as React.ReactElement<GridActionsCellItemProps>[];

      if (row?.state === 'PENDING') {
        actions.push(<GridActionsCellItem showInMenu={true} onClick={() => onClickConsume(row)} label={t`Consume`} />);
        actions.push(<GridActionsCellItem showInMenu={true} onClick={() => onClickCancel(row)} label={t`Cancel`} />);
      }

      if (row?.state === 'ISSUED') {
        actions.push(<GridActionsCellItem showInMenu={true} onClick={() => onClickConsume(row)} label={t`Consume`} />);
        actions.push(<GridActionsCellItem showInMenu={true} onClick={() => onClickRefund(row)} label={t`Refund`} />);
      }

      return actions;
    }
  },
];

export const helper = {
  columns
};

export default helper;
