// @ts-nocheck
import React from 'react';
import {Cached, Menu} from '@mui/icons-material';
import {AppBar as MuiAppBar, Box, IconButton, Toolbar, Typography, useMediaQuery} from '@mui/material';
import logoMobile from '../../assets/sata-logo-mobile.png';
import {actions, useStore} from '../../store/valtio';
import logoDesktop from '../../assets/sata-logo.png';
import UserMenu from './UserMenu';
import {useTheme} from "@mui/material";
import {APP_NAME} from "../../config/settings";

export const Appbar = () => {
  const {app} = useStore();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const logo = isTablet ? logoMobile : logoDesktop;

  return (
    <MuiAppBar position={'fixed'} logo={logo} sx={styles.root(isTablet, app.sideBarOpen)} elevation={2}>
      <Toolbar sx={styles.toolbar}>
        <IconButton onClick={actions.app.toggleSideBarOpen} sx={styles.menuIcon} aria-label='menu'>
          <Menu />
        </IconButton>
        <Box sx={styles.barHolder}>
          <Box sx={styles.barHolderLeft}>
            <Typography sx={styles.appName} variant='h4'>
              {APP_NAME}
            </Typography>
            <IconButton onClick={() => window.location.reload()}>
              <Cached />
            </IconButton>
          </Box>
          <UserMenu />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

const styles = {
  root: (isTablet, open) => {
    let dynamicStyles = {width: '100%'};
    if (!isTablet && !open) dynamicStyles = {width: 'calc(100% - 3.5rem)', marginLeft: '3.5rem'};
    if (!isTablet && open) dynamicStyles = {width: 'calc(100% - 15.5rem)', marginLeft: '15.5rem'};

    return {
    color: 'secondary.main',
    backgroundColor: 'common.white',
    zIndex: (theme) => theme.zIndex.drawer + 1,
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    height: '3.75rem',
    ...dynamicStyles
  }},
  image: {
    height: '2.625rem',
    cursor: 'pointer'
  },
  appName: {
    fontSize: '1.25rem',
    marginLeft: '0.8rem',
    display: {xs: 'none', sm: 'unset'},
    color: 'text.secondary'
  },
  toolbar: {
    height: '3.75rem',
    // paddingLeft: "0 !important",
  },
  barHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: 'primary.main'
  },
  barHolderLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  }
};

export default Appbar;
