// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import {Box, IconButton} from "@mui/material";
import {Check, Close, DeleteOutlined, EditOutlined} from "@mui/icons-material";

export const columns = ({ editHandler, removeHandler }) => [
  {
    field: 'name',
    headerName: t`Name`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    minWidth: 200
  },
  {
    field: 'email',
    headerName: t`Email`,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text',
    flex: 3,
    minWidth: 175
  },
  {
    field: 'disabled',
    type: 'boolean',
    headerName: t`Active`,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 75,
    renderCell: ({ row }) => (
      !row.disabled ? <Check color={'success'}/> : <Close color={'error'}/>
    )
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }) => {
      return (
        <Box>
          <IconButton
            aria-label='delete'
            color='secondary'
            onClick={() => editHandler(row)}
          >
            <EditOutlined fontSize='inherit' />
          </IconButton>
          <IconButton
            aria-label='delete'
            color='secondary'
            onClick={() => removeHandler(row)}
          >
            <DeleteOutlined fontSize='inherit' />
          </IconButton>
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
