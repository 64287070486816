// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import { useSendByEmailRefundMutation } from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';
import {Button} from "@mui/material";
import {ForwardToInboxOutlined} from "@mui/icons-material";

const SendByEmailRefund = ({ refundId }) => {
  const [sendByEmailRefund, { data, error, isLoading }] =
    useSendByEmailRefundMutation();

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={() => sendByEmailRefund(refundId)}
        startIcon={<ForwardToInboxOutlined/>}
      >{t`SEND BY EMAIL`}</Button>
      <RTKQueryFeedback error={error} isLoading={isLoading} />
    </>
  );
};

SendByEmailRefund.propTypes = {};

SendByEmailRefund.defaultProps = {};

export default SendByEmailRefund;
