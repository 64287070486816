// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {validationSchema} from './validationSchema';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

const RefundSubjectFormDialogProvider = ({ refundSubject, currencyCode, children }) => {

  const methods = useForm({
    defaultValues: {currencyCode, totalRefund: 0, ...refundSubject},
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...methods}>
      {children}
    </FormProvider>
  );
};

RefundSubjectFormDialogProvider.propTypes = {
  refundSubject: PropTypes.shape({}),
  currencyCode: PropTypes.string,
};

RefundSubjectFormDialogProvider.defaultProps = {
  refundSubject: null,
  currencyCode: null,
};

export default RefundSubjectFormDialogProvider;
