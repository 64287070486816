// @ts-nocheck
import {t} from "ttag";
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, LinearProgress, Theme, Typography} from '@mui/material';
import {useAccount, useIsAuthenticated, useMsal, useMsalAuthentication} from "@azure/msal-react";
import {AuthenticationResult, InteractionStatus, InteractionType} from "@azure/msal-browser";
import {MSA_AUTHORITY, MSA_CLIENT_ID, MSA_SCOPES_CCS} from '../../../config/settings';
import logo from '../../../assets/sata-logo.png';
import msalInstance from "../../../utils/msal-instance";

const request = {
    scopes: MSA_SCOPES_CCS,
    authority: MSA_AUTHORITY,
    clientId: MSA_CLIENT_ID
};

const Login = () => {
    const {instance, inProgress} = useMsal();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const {login} = useMsalAuthentication(InteractionType.Redirect, request);
    const account = useAccount();

  const handleLogin = (response: AuthenticationResult | null) => {
    const account = instance.getActiveAccount();
    const redirect = (window.localStorage.getItem('_redirect_') || '/') + (window.localStorage.getItem('_params_') || '');

    if (response?.accessToken) {
      localStorage.setItem('accessToken', response.accessToken);
    }
    if (response?.account) {
      instance.setActiveAccount(response.account);
      window.location.href = redirect;
    } else if (instance.getAllAccounts().length > 0) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      window.location.href = redirect;
    } else if (!account) {
      login().then(handleLogin).catch(error => {
        console.error("Login error: ", error);
      });
    }
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (isAuthenticated && account && localStorage.getItem('accessToken')) {
        navigate('/');
      } else {
        msalInstance.acquireTokenSilent({
          scopes: ["User.Read"]
        }).then(handleLogin).catch(() => {
          msalInstance.acquireTokenRedirect({
            scopes: ["User.Read"]
          }).then(handleLogin).catch(error => {
            console.error("Login error: ", error);
          });
        });
      }
    }
  }, [isAuthenticated, inProgress, login, navigate]);

    return (
        <Box sx={classes.root}>
            <Typography align='center' variant='h2'>{t`Login, please wait...`}</Typography>
            <Box width='20rem'>
                <LinearProgress/>
            </Box>
            <Typography
                component='p'
                sx={classes.caption}
                variant='caption'
            >{t`Contacting Microsoft Azure...`}</Typography>
            <Box mt={10} display='flex' justifyContent='center'>
                <Box
                    sx={classes.image}
                    component={'img'}
                    alt='Under development'
                    src={`/static/images/undraw_Secure_server_re_8wsq.svg`}
                />
            </Box>
            <Box mt={2} display='flex' justifyContent='flex-end' width='24rem'>
                <Box
                    sx={classes.logo}
                    component={'img'}
                    alt='SATA'
                    src={logo}
                />
            </Box>
        </Box>
    );
};

const classes = {
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: (theme: Theme) => theme.spacing(3),
        paddingTop: (theme: Theme) => theme.spacing(10)
    },
    caption: {
        justifyContent: 'flex-start',
        marginTop: (theme: Theme) => theme.spacing(1),
        display: 'flex',
        width: '20rem'
    },
    image: {
        maxWidth: '100%',
        width: '35rem',
        maxHeight: '16.75rem',
        height: 'auto'
    },
    logo: {
        width: '9.375rem'
    }
};

export default Login;
