// @ts-nocheck
import React, {useState} from 'react';
import {t} from 'ttag';
import {Add} from '@mui/icons-material';
import RefundsList from './RefundsList';
import DeleteRefundDialog from './refund-forms/DeleteRefundDialog';
import {useNavigate} from "react-router-dom";
import {Button, CardHeader, Divider, Paper, Typography} from "@mui/material";

const Refunds = () => {

  const navigate = useNavigate();

  const [refundToDelete, setRefundToDelete] = useState(null);

  return (
    <Paper elevation={3} sx={{p: 2}}>
      <CardHeader
        sx={{p: 0, pr: 1}}
        title={<Typography sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>{t`Refunds`}</Typography>}
        action={
        <Button
          variant={'contained'}
          color={'primary'}
          sx={{fontFamily: 'NeoSansProMedium'}}
          onClick={() => navigate('/refunds/create')}
          startIcon={<Add/>}
        >
          {t`NEW REFUND`}
        </Button>
        }
      />
      <Divider sx={{mx: -2, my: 2}} />
      <RefundsList
        viewHandler={({refundId}) => navigate(`/refunds/${refundId}`)}
        removeHandler={setRefundToDelete}
      />
      {refundToDelete && (
        <DeleteRefundDialog
          refund={refundToDelete}
          onClose={() => setRefundToDelete(null)}
        />
      )}
    </Paper>
  );
};

export default Refunds;

