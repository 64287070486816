// @ts-nocheck
import {axiosInstance} from './auth-axios-instance';
import AxiosError from './AxiosError';
import {useStore} from '../../../store/valtio';

export const axiosBaseQuery = ({baseUrl}:{baseUrl: string|undefined;} = {baseUrl: ''}) => {
  return async ({url = '', method, body: data, ...rest}) => {
    try {
      return {data: await axiosInstance({url: url.includes('http') ? url : baseUrl + url, method, data, ...rest})};
    } catch (e: any) {
      if (e?.response?.status === 401) {
        window.location.href = '/403';
      }
      if (!e?.response) {
        window.location.href = '/403';
      }
      const message =
        (e?.response?.data?.message
            ? e?.response?.data?.message
            : e?.response?.data?.[Object.keys(e?.response?.data)[0]]
        ) || e?.message;
      throw new AxiosError({message: message, status: e?.response?.status, data: JSON.stringify(e?.response?.data)});
    }
  };
};
