// @ts-nocheck
import React from 'react';
import GenericError from './GenericError';

export const NotFound = () => {
  return (
    <GenericError
      title={`404: The page you are looking for isn't here`}
      description={`You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.`}
      imgSrc={`/static/images/undraw_not_found_60pq.svg`}
    />
  );
};

export default NotFound;
