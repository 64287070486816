// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {FormControlLabel, Grid, Switch, TextField} from '@mui/material';

const OfficeForm = ({formik}) => {

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Office Code`}
          name={'officeCode'}
          color={'primary'}
          variant={'outlined'}
          onChange={e => formik.setFieldValue('officeCode', e.target.value.toUpperCase())}
          value={formik.values?.officeCode || ''}
          error={Boolean(formik.errors.officeCode)}
          helperText={formik.errors.officeCode || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Currency Code`}
          name={'currencyCode'}
          color={'primary'}
          variant={'outlined'}
          onChange={e => formik.setFieldValue('currencyCode', e.target.value.toUpperCase())}
          value={formik.values?.currencyCode || ''}
          error={Boolean(formik.errors.currencyCode)}
          helperText={formik.errors.currencyCode || ''}
          required
          fullWidth
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Company Code`}
          name={'companyCode'}
          color={'primary'}
          variant={'outlined'}
          onChange={e => formik.setFieldValue('companyCode', e.target.value.toUpperCase())}
          value={formik.values?.companyCode || ''}
          error={Boolean(formik.errors.companyCode)}
          helperText={formik.errors.companyCode || ''}
          fullWidth
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Ticket Code`}
          name={'ticketCode'}
          color={'primary'}
          variant={'outlined'}
          onChange={e => formik.setFieldValue('ticketCode', e.target.value.toUpperCase())}
          value={formik.values?.ticketCode || ''}
          error={Boolean(formik.errors.ticketCode)}
          helperText={formik.errors.ticketCode || ''}
          fullWidth
        />
      </Grid>
      <Grid xs={12} md={12} item>
        <TextField
          label={t`Description`}
          name={'description'}
          color={'primary'}
          variant={'outlined'}
          multiline
          minRows={2}
          maxRows={4}
          onChange={e => formik.setFieldValue('description', e.target.value)}
          value={formik.values?.description || ''}
          error={Boolean(formik.errors.description)}
          helperText={formik.errors.description || ''}
          fullWidth
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <FormControlLabel
          label={t`Active`}
          control={(
            <Switch
              name={'active'}
              onChange={formik.handleChange}
              checked={Boolean(formik.values?.active)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

OfficeForm.propTypes = {
  formik: PropTypes.object,
};

OfficeForm.defaultProps = {
  formik: {}
};

export default OfficeForm;
