import {
  MSA_AUTHORITY,
  MSA_CLIENT_ID
} from '../config/settings';
import {LogLevel, PublicClientApplication} from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: MSA_CLIENT_ID as string,
    authority: MSA_AUTHORITY,
    redirectUri: window.location.origin + '/',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: false,
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (!containsPii) { console.log(message); }
    //   },
    //   piiLoggingEnabled: false,
    //   logLevel: LogLevel.Verbose,
    // }
  }
});

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

export default msalInstance;
