// @ts-nocheck
import React from 'react';
import {Box, List, SwipeableDrawer, Typography, useMediaQuery} from '@mui/material';
import logoMobile from "../../assets/sata-logo-mobile.png";
import {actions, useStore} from '../../store/valtio';
import logoDesktop from "../../assets/sata-logo.png";
import SidebarMenu from '../../SidebarMenu';
import {useTheme} from "@mui/material";
import {APP_VERSION} from "../../config/settings";

const Sidebar = () => {
  const {app} = useStore();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const logo = !app.sideBarOpen ? logoMobile : logoDesktop;

  return (
    <SwipeableDrawer
      variant={isTablet ? 'temporary' : 'permanent'}
      sx={styles.root(isTablet, app.sideBarOpen)}
      open={app.sideBarOpen}
      onClose={() => actions.app.setSideBarOpen(false)}
      onOpen={() => actions.app.setSideBarOpen(true)}
    >
      <Box display={'flex'} sx={{backgroundColor: 'white', height: '3.75rem'}}>
          <Box component={'img'} sx={styles.image} src={logo} alt={'CCS'} height={'2.2rem'} m={'auto'}/>
      </Box>
      <List sx={styles.list}>
        <SidebarMenu />
      </List>
      <Typography variant={'caption'} sx={{...styles.appVersionLabel(isTablet)}} id={'qwer'}>
        <small>
          <i>{APP_VERSION}</i>
        </small>
      </Typography>
    </SwipeableDrawer>
  );
};

const styles = {
  root: (isMobile, open) => {
    let dynamicStyles = {width: '15.4rem'};
    if (isMobile && !open) dynamicStyles = {width: 0, marginTop: 0};
    if (isMobile && open) dynamicStyles = {width: '15.4rem', marginTop: 0};
    if (!isMobile && !open) dynamicStyles = {width: '3.5rem'};

    return {
      zIndex: (theme) => theme.zIndex.drawer + 1,
      ...dynamicStyles,
      [`& .MuiDrawer-paper`]: {
        color: (theme) => theme.palette.secondary.contrastText,
        backgroundColor: (theme) => theme.palette.secondary.main,
        border: 'unset',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        ...dynamicStyles
      }
    };
  },
  list: {
    padding: 0,
    overflowX: "hidden"
  },
  title: {
    opacity: 0.54,
    color: (theme) => theme.palette.common.primary,
    padding: 0,
    margin: (theme) => theme.spacing(2),
    fontFamily: (theme) => theme.typography.h2.fontFamily,
    flexGrow: 1,
    lineHeight: '1.3'
  },
  appVersionLabel: (isTablet) => ({
    opacity: 0.34,
    position: 'absolute',
    bottom: isTablet ? '1px' : '55px',
    textAlign: 'center',
    width: '100%'
  }),
  menuIcon: {
    backgroundColor: (theme) => theme.palette.secondary.dark,
    margin: 0,
    height: '3.125rem',
    color: (theme) => theme.palette.secondary.contrastText,
    minWidth: '3.125rem',
    padding: (theme) => theme.spacing(2),
    borderRadius: '0.125rem'
  },
  icon: {
    color: (theme) => theme.palette.secondary.contrastText,
    minWidth: 'unset',
    width: '1.5rem',
    marginRight: (theme) => theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  activeButton: {
    backgroundColor: (theme) => theme.palette.secondary.light,
    borderLeftWidth: '0.250rem',
    borderStyle: 'solid',
    borderLeftColor: (theme) => theme.palette.primary.main,
    paddingLeft: (theme) => theme.spacing(1) * 1.5,
    '&:hover': {
      backgroundColor: (theme) => theme.palette.secondary.light
    },
    image: {
      height: '2.625rem',
      cursor: 'pointer'
    },
  }
};

export default Sidebar;
