import React, {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, Typography, Theme} from '@mui/material';
import {t} from 'ttag';


interface GenericErrorProps {
  title: string,
  description: string,
  imgSrc: string,
  children: ReactElement,
}
export const GenericError = ({title, description, imgSrc, children} : GenericErrorProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={classes.root}>
      <Container maxWidth='lg'>
        <Typography align='center' variant={'h1'} fontSize={'2.5rem'} color='textPrimary'>
          {title}
        </Typography>
        <Typography align='center' variant='subtitle2' color='textSecondary'>
          {description}
        </Typography>
        <Box mt={6} display='flex' justifyContent='center'>
          <Box component={'img'} sx={classes.image} alt='error view' src={imgSrc} />
        </Box>
        <Box mt={6} display='flex' justifyContent='center'>
          {children || (
            <Button color='primary' onClick={() => navigate('/')} variant='outlined'>
              {t`Home`}
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

GenericError.defaultProps = {
  title: 'Whoops...',
  description: '',
  imgSrc: `/static/images/undraw_close_tab_uk6g.svg`,
  children: null,
};

const classes = {
  root: {
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: (theme: Theme) => theme.spacing(3),
    paddingTop: '80px',
    paddingBottom: '80px'
  },

  image: {
    maxWidth: '100%',
    width: '560px',
    maxHeight: '300px',
    height: 'auto'
  }
};

export default GenericError;
