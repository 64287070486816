// @ts-nocheck
import React, {useState} from 'react';
import {Search} from '@mui/icons-material';
import {InputAdornment, OutlinedInput} from '@mui/material';
import {useDebouncedCallback} from 'use-debounce';

type PatternSearchProps = {
  placeholder: string,
  onChange: (value: string) => void,
  value?: string,
  fullWidth?: boolean
  rest?: any
}

export const PatternSearch = ({placeholder, onChange, ...rest}: PatternSearchProps) => {
  const [patternInput, setPatternInput] = useState<string>(rest.value || '');
  const handlePattern = useDebouncedCallback((value: string) => onChange(value), 750);

  return (
    <OutlinedInput
      sx={styles.textField}
      value={patternInput}
      onChange={(e) => {
        setPatternInput(e.target.value);
        handlePattern(e.target.value);
      }}
      id="outlined-adornment-search"
      type={'text'}
      placeholder={placeholder}
      size={'small'}
      endAdornment={
        <InputAdornment position="end">
          <Search/>
        </InputAdornment>
      }
    />
  );
};

const styles = {
  textField: {
    paddingRight: 0,
    '& .MuiOutlinedInput-root': {
      paddingRight: 0,
    },
    '& .MuiInputAdornment-root': {
      borderLeft: 'solid 1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      backgroundColor: '#F9FAFC',
      paddingX: ' 14px',
      paddingY: ' 7px'
    },
  }
}

export default PatternSearch;
