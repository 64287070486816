// @ts-nocheck
export const type = 'formOfPayment';
export const endpoints = (builder) => ({
  // Get formsOfPayment list
  getFormsOfPayment: builder.query({
    query: (params) => ({ url: `/forms-of-payment`, params, destructFn: (d) => d?.data }),
    providesTags: ( data = [] ) => {
      return data?.data?.map(({ formOfPaymentId }) => ({ type, id: formOfPaymentId }))
        .concat({ type, id: 'list' });
    }
  }),

  // Create formOfPayment
  createFormOfPayment: builder.mutation({
    query: (formOfPayment) => ({
      url: '/forms-of-payment',
      method: 'POST',
      body: formOfPayment
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  // Edit formOfPayment
  editFormOfPayment: builder.mutation({
    query: (formOfPayment) => ({
      url: `/forms-of-payment/${formOfPayment.formOfPaymentId}`,
      method: 'PATCH',
      body: formOfPayment
    }),
    invalidatesTags: (result, error, { formOfPaymentId }) => [
      { type, id: formOfPaymentId }
    ]
  }),

  // Delete formOfPayment
  deleteFormOfPayment: builder.mutation({
    query: (formOfPayment) => ({
      url: `/forms-of-payment/${formOfPayment.formOfPaymentId}`,
      method: 'DELETE'
    }),
    invalidatesTags: [{ type, id: 'list' }]
  })
});

export default endpoints;
