import React, {useEffect, useState} from 'react';
import {Box, Card, CardHeader, Divider, Grid2, Skeleton, Typography} from '@mui/material';
import {t} from 'ttag';
import {useGetClaimStatusMutation} from '../../store/apis/ccs';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {enqueueSnackbar} from 'notistack';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import PassengerDetails from './components/PassengerDetails';
import ClaimTimeline from './components/ClaimTimeline';
import RelatedIncidents from './components/RelatedIncidents';
import {IClaim} from '../../store/apis/interfaces/claim';

interface ClaimViewProps {
  response?: IClaim;
}

export const ClaimView = ({response}: ClaimViewProps) => {
  const [onSubmitSearch, {isLoading}] = useGetClaimStatusMutation();
  const [data, setData] = useState(response);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>(t`SUMMARY`);

  window.scrollTo({top: 0, behavior: 'instant'});

  useEffect(() => {
    if (!data && searchParams.get('process')) {
      onSubmitSearch({process: searchParams.get('process') as string}).then((e: any) => {
        if (e?.error) {
          enqueueSnackbar(e?.error?.message || t`Invalid process reference`, {variant: 'error'});
          navigate('/claims');
        }
        else {
          setData(e?.data);
        }
      });
    }
  }, [data, searchParams, setData]);

  return (
    <Card>
      <CardHeader
        sx={{mr: 1}}
        title={
        <Box display={'flex'} gap={0.5} alignItems={'center'}>
          <Typography sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>{t`Check Claim` + ':'}</Typography>
          {isLoading ? <Skeleton variant={'rounded'} height={25} width={200}/> : <Typography sx={{fontSize: '1.25rem'}}>{searchParams.get('process')}</Typography>}
        </Box>
        }
      />
      <Divider/>
{/*      <Tabs
        value={tab}
        indicatorColor={'primary'}
        textColor={'secondary'}
        onChange={(_, tab) => setTab(tab)}
        aria-label='Separators menu'
        variant={'scrollable'}
        ScrollButtonComponent={(props) => (
          <TabScrollButton
            {...props}
            sx={{ width: '25px', '&.Mui-disabled': {display: 'none'} }}
          />
        )}
        scrollButtons={'auto'}
        allowScrollButtonsMobile={true}
      >
        <Tab sx={styles.tab} label={t`Summary`} value={'SUMMARY'}></Tab>
        <Tab sx={styles.tab} label={t`Complaint Information`} value={'INFORMATION'}></Tab>
        <Tab sx={styles.tab} label={t`Refunds`} value={'REFUNDS'}></Tab>
        <Tab sx={styles.tab} label={t`Relation between Incidents`} value={'RELATIONS'}></Tab>
      </Tabs>*/}
      {tab === 'SUMMARY' &&
        <Grid2 container p={2} spacing={2} rowSpacing={4}>
          <Grid2 size={{xs: 12, xl: 6}}>
            <PassengerDetails data={data}/>
          </Grid2>
          {/*<Grid2 size={{xs: 12, xl: 6}}>
            <ClaimTimeline data={data}/>
          </Grid2>*/}
          <Grid2 size={{xs: 12, xl: 12}}>
            <RelatedIncidents title={t`Related Incidents`} rows={data?.RelatedIncidents}/>
          </Grid2>
          <Grid2 size={{xs: 12, xl: 12}}>
            <RelatedIncidents title={t`User Related Incidents`} rows={data?.OtherUserIncidents}/>
          </Grid2>
        </Grid2>
      }
      <RTKQueryFeedback isLoading={isLoading}/>
    </Card>
  )
}

const styles = {
  tab: {
    fontFamily: 'NeoSansProMedium',
    px: 2
  }
}

export default ClaimView;
