// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import EmployeeForm from './EmployeeForm';
import { employeeValidationSchema } from './helpers';
import {
  useEditUserMutation,
  useGetUserQuery
} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";

const EditEmployeeDialog = ({ employee: { employeeId }, open, onClose }) => {
  const {
    data: employee,
    error: employeeError,
    isLoading: employeeIsLoading
  } = useGetUserQuery(employeeId);
  const [editUser, { data, error, isLoading }] = useEditUserMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: employee,
    validationSchema: employeeValidationSchema,
    onSubmit: (values) => {
      editUser({
        ...values,
        roles: values.roles.map((e) => e.name)
      }).then((r) => {
        if (!r.error) {
          enqueueSnackbar(t`The employee` + ` ${r?.data?.employeeId} ` + t`was successfully edited!`, {variant: 'success'});
          onClose();
      }});
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Edit Employee`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <EmployeeForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={formik.handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback
        error={error || employeeError}
        isLoading={isLoading || employeeIsLoading}
        data={data}
      />
    </>
  );
};

EditEmployeeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditEmployeeDialog.defaultProps = {
  open: true
};

export default EditEmployeeDialog;
