// @ts-nocheck
import { t } from 'ttag';
import React, { useMemo } from 'react';

import { usePrintRefundMutation } from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';
import {Button} from "@mui/material";
import {LocalPrintshopOutlined} from "@mui/icons-material";

const PrintRefund = ({ refundId }) => {
  const [generatePDf, { data, error, isLoading }] = usePrintRefundMutation();

  useMemo(() => {
    if (data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }, [data]);

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        onClick={() => {
          generatePDf(refundId);
        }}
        startIcon={<LocalPrintshopOutlined/>}
      >{t`PRINT`}</Button>
      <RTKQueryFeedback isLoading={isLoading} error={error} />
    </>
  );
};

PrintRefund.propTypes = {};

PrintRefund.defaultProps = {};

export default PrintRefund;
