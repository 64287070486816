
import React, {ReactElement} from 'react';
import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material';
import themeData from './theme';

interface ThemeProviderProps {
  children: ReactElement,
}

export const theme = createTheme(themeData);

const ThemeProvider = ({children} : ThemeProviderProps) => {

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;