// @ts-nocheck
import { t } from 'ttag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useGetAllRolesQuery } from '../../store/apis/ccs';
import RTKQueryFeedback from '../common/RTKQueryFeedback';
import {Autocomplete, Box, Chip, TextField} from "@mui/material";

const RolesSearch = ({ value, onChange }) => {
  const { data, error, isLoading } = useGetAllRolesQuery();
  const [input, setInput] = useState('');

  const onDelete = (roleId) => () => {
    onChange(value.filter((v) => v.roleId !== roleId));
  };

  const handleInputChange = (e, value) => {
    setInput(value);
  };
  return (
    <>
      <RTKQueryFeedback error={error} isLoading={isLoading} />
      <Autocomplete
        multiple
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isLoading}
        disableClearable
        options={data?.data || []}
        inputValue={input}
        renderTags={() => null}
        getOptionLabel={(opt) => `${opt.name}`}
        onInputChange={handleInputChange}
        onChange={(_, roles) => onChange(roles)}
        getOptionSelected={(option, value) => value.roleId === option.roleId}
        renderInput={(params) => (
          <TextField {...params} variant='outlined' placeholder={t`Roles`} />
        )}
      />
      <Box display={'flex'} maxWidth={'100%'} flexWrap={'wrap'} alignItems={'center'} pt={2} gap={2}>
        {value.map((v) => (
          <Chip key={v.roleId} label={v.name} onDelete={onDelete(v.roleId)} />
        ))}
      </Box>
    </>
  );
};

RolesSearch.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

RolesSearch.defaultProps = {
  value: []
};

export default RolesSearch;
