// @ts-nocheck
import React, {useState} from 'react';
import {columns} from './formsOfPaymentListHelper';
import {useGetFormsOfPaymentQuery} from '../../../store/apis/ccs';
import PropTypes from 'prop-types';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {DataGrid} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import {t} from "ttag";
import PatternSearch from "../../../components/pattern-search/PatternSearch";
import table from "../../../components/common/styles";

const FormsOfPaymentList = ({editHandler, removeHandler}) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterPattern, setFilterPattern] = useState(null);
  const {data = [], error, isFetching} = useGetFormsOfPaymentQuery({pattern: filterPattern});

  return (
    <>
      <RTKQueryFeedback error={error}/>
      <Box sx={{mb: 3}}>
          <PatternSearch
              fullWidth={true}
              placeholder={t`Search`}
              value={searchValue}
              onChange={(e) => {
                  setSearchValue(e.target.value);
                  setFilterPattern(e.target.value);
              }}
          />
      </Box>
      <DataGrid
        pagination
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        paginationModel={{page: page - 1, pageSize: data?.pagination?.rowsPerPage || 12}}
        rowCount={data?.count || 0}
        onPaginationModelChange={(pagination) => {
            setPage(pagination.page + 1);
        }}
        getRowId={({formOfPaymentId}) => formOfPaymentId}
        columns={columns({editHandler, removeHandler})}
        loading={isFetching}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
        sx={table}
      />
    </>

  );
};

FormsOfPaymentList.propTypes = {
  editHandler: PropTypes.func.isRequired,
  removeHandler: PropTypes.func.isRequired,
};

export default FormsOfPaymentList;
