// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {ListItemAvatar} from "@mui/material";
import StatusChip from "../status/StatusChip";

const History = ({ avatar, name, date, state }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={name} src={avatar} />
      </ListItemAvatar>
      <ListItemText primary={name} secondary={date} />
      <ListItemSecondaryAction>
        <StatusChip state={state} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

History.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  state: PropTypes.string
};

History.defaultProps = {
  avatar: 'https://i.imgur.com/mCHMpLT.png'
};

export default History;
