// @ts-nocheck
import {t} from 'ttag';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DateTime, Interval} from 'luxon';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {
  styled,
  Box,
  Button,
  Divider,
  lighten,
  Popover,
  Tab,
  Tabs,
  TabScrollButton,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import {CalendarPicker, LocalizationProvider, PickersDay} from '@mui/x-date-pickers';
import {CalendarToday, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import useCalendarShortcuts from "./useCalendarShortcuts";

const StyledButton = {
  leftArrow: {
    minWidth: '30px !important',
    px: '0px !important',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: '#F9FAFC !important',
    '&:hover': {
      borderColor: 'rgba(0, 40, 87, 1)',
    }
  },
  main: (open: boolean, size: string) => ({
    py: size === 'small' ? '0.25px' : '8px',
    border: open ? '1px solid rgba(0, 40, 87, 1)' : '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 0,
    borderRight: open ? '0.5px solid rgba(0, 40, 87, 1)' : 'none',
    borderLeft: open ? '0.5px solid rgba(0, 40, 87, 1)' : 'none',
    backgroundColor: open ? 'rgba(0, 40, 87, 0.1) !important' : 'inherit',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid rgba(0, 40, 87, 1)',
      borderRight: '0.5px solid rgba(0, 40, 87, 1)',
      borderLeft: '0.5px solid rgba(0, 40, 87, 1)'
    }
  }),
  rightArrow: {
    minWidth: '30px !important',
    px: '0px !important',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: '#F9FAFC !important',
    '&:hover': {
      borderColor: 'rgba(0, 40, 87, 1)',
    }
  }
}

const StyledDatePickerWrapper = styled(Box)(({theme}) => ({
  width: useMediaQuery('(max-width: 768px)') ? '320px !important' : '820px !important',
  display: 'flex',
  flexDirection: useMediaQuery('(max-width: 768px)') ? 'column' : 'row',
  '& .selection-shortcuts': {
    display: 'block',
    marginTop: '18px',
    padding: '6px',
    button: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '8px',
      backgroundColor: 'transparent',
      borderWidth: 0,
      '&:hover': {
        backgroundColor: '#f8f9fa'
      }
    }
  },

  '& .MuiCalendarPicker-root': {
    overflow: 'hidden',
    maxWidth: useMediaQuery('(max-width: 768px)') && '280px !important',
    maxHeight: useMediaQuery('(max-width: 768px)') && '340px !important',
  },

    '& .MuiPickersCalendarHeader-root': {
      padding:  useMediaQuery('(max-width: 768px)') ? '7px' : '16px',
    },

  '& .date-picker-calendar-wrapper': {
    '& .date-calendars-selector': {
      display: useMediaQuery('(max-width: 768px)') ? 'block ': 'flex',
      width: useMediaQuery('(max-width: 768px)') ? '320px !important' : '642px !important',
      '& .picker-wrapper': {
        maxHeight: '315px',
        '& .regular-day': {
          button: {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.dark
          }
        },
        '& .selected-day': {
          '&.start-day': {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
            button: {
              backgroundColor: theme.palette.primary.light,
              color: 'white',
            }
          },
          '&.end-day': {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
            button: {
              backgroundColor: theme.palette.primary.light,
              color: 'white'
            }
          },
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
          button: {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.dark
          }
        }
      }
    }
  },
}));

const StyledDateRangePicker = styled('div')(({theme}) => ({
  display: 'block',
  width: '100%',
  button: {
    backgroundColor: theme.palette.background.paper
  }
}));

/**
 * Calculates the pivot date used to set the max date of left calendar
 * and min date of right calendar.
 *
 * @param range
 * @returns {Date}
 */
const calculateFrontier = (range) => {
  return DateTime.fromJSDate(range.startDate).startOf('month').toJSDate();
};

export const DateRangePicker = ({onChange = () => {}, range = {
  startDate: DateTime.now().minus({day: 1}).toJSDate(),
  endDate: DateTime.now().toJSDate()
}, disabled = false, disableFuture = false, size = 'small'}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(range.startDate);
  const [endDate, setEndDate] = useState(range.endDate);
  const [selected, setSelected] = useState(false);
  const [frontier, setFrontier] = useState(calculateFrontier(range));
  const [first, setFirst] = useState(true);
  const open = Boolean(anchorEl);
  const shortcuts = useCalendarShortcuts();
  const getCurrentRangeIsShortcut = (range, index?: boolean) => {
    if (!index) {
      return shortcuts.find(s =>
        DateTime.fromJSDate(s.startDate).toISODate() === DateTime.fromJSDate(range.startDate).toISODate() &&
        DateTime.fromJSDate(s.endDate).toISODate() === DateTime.fromJSDate(range.endDate).toISODate()
      );
    }
    if (index) {
      return shortcuts.findIndex(s =>
        DateTime.fromJSDate(s.startDate).toISODate() === DateTime.fromJSDate(range.startDate).toISODate() &&
        DateTime.fromJSDate(s.endDate).toISODate() === DateTime.fromJSDate(range.endDate).toISODate()
      )
    }
  }

  /**
   * Handles input button click event, opening the popover
   *
   * @param event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the popover close event.
   *
   * If the user does not click the "Select dates" button the internal value for
   * start date and end date is reset to the prop range values, as the user has
   * clicked outside the popover, thus not selecting any dates.
   */
  const handleClose = () => {
    if (!selected) {
      setStartDate(range.startDate);
      setEndDate(range.endDate);
    }
    setAnchorEl(null);
  };

  /**
   * Calculates the selected date on right calendar. This moves the calendar to the
   * month of that date.
   *
   * @param {object} range
   * @param {Date} f
   * @param {Date|null} rightCal
   * @returns {null|Date|*}
   */
  const calculateRightCalDate = (range, f = frontier, rightCal = null) => {
    const pivot = range.endDate ? range.endDate : range.startDate;
    const today = DateTime.fromJSDate(f).endOf('month').plus({day: 1}).toJSDate();
    const rightValue = rightCal ? rightCal : today;
    if (DateTime.fromJSDate(pivot) >= today) {
      return pivot;
    }

    // This checks that right calendar is not on a month prior
    // to the pivot/frontier date (disabled part of calendar)
    if (DateTime.fromJSDate(rightValue) <= today) {
      return today;
    }

    return null;
  };

  /**
   * Calculates the selected date on left calendar. This moves the calendar to the
   * month of that date.
   *
   * @param range
   * @param f
   * @returns {null|*}
   */
  const calculateLeftCalDate = (range, f = frontier) => {
    const today = DateTime.fromJSDate(f).endOf('month').toJSDate();
    if (DateTime.fromJSDate(range.startDate) <= today) {
      return range.startDate;
    }

    return null;
  };

  const [rightCalDate, setRightCalDate] = useState(calculateRightCalDate(range));
  const [leftCalDate, setLeftCalDate] = useState(calculateLeftCalDate(range));

  /**
   * Resets the selected dates on calendars based on new star date and end date change.
   *
   * @param {Object} range
   * @param {Date} f
   * @param {Date|null} rightCalDate
   */
  const refreshCalendars = (range, f = frontier, rightCalDate = null) => {
    const rightDate = calculateRightCalDate(range, f, rightCalDate);
    if (rightDate) {
      setRightCalDate(rightDate);
    }

    const leftDate = calculateLeftCalDate(range, f);
    if (leftDate) {
      setLeftCalDate(leftDate);
    }

    if (DateTime.fromJSDate(range.endDate).hasSame(DateTime.fromJSDate(range.startDate), 'day')) {
      const focus = DateTime.fromJSDate(range.endDate).endOf('month').plus({day: 1}).toJSDate();
      setRightCalDate(focus);
    }
  };

  /**
   * Handles calendar clicks.
   *
   * This sets the start date and end date in turns. It also triggers the update
   * of frontier date and calendars selected dates.
   * @param {Date} date
   */
  const handleDateSelected = (date) => {
    date = date.toJSDate();

    let _startDate = first ? date : startDate;
    let _endDate = date;

    if (!first && _startDate > _endDate) {
      const end = _endDate;
      _endDate = _startDate;
      _startDate = end;
    }

    const range = {startDate: _startDate, endDate: _endDate};
    const f = calculateFrontier(range);
    refreshCalendars(range, f, rightCalDate);

    setStartDate(_startDate);
    setEndDate(_endDate);
    setFrontier(f);
    setFirst(!first);
  };

  /**
   * Creates a JSX element that calendar used to render a day in the month grid.
   *
   * This element is set to work with imported SCSS rules to display the start
   * and end dates as selected and all other days in between those two. Calendar
   * default selected day behavior is overridden.
   *
   * @param day
   * @param selectedDates
   * @param props
   * @returns {JSX.Element}
   */
  const handleRenderDay = (day, selectedDates, props) => {
    const { key, ...otherProps } = props;
    const dayComponent = <PickersDay {...otherProps} />;

    if (!endDate) return dayComponent;
    const {outsideCurrentMonth} = props;
    const dayInCurrentMonth = !outsideCurrentMonth;

    const fixedEndDate = DateTime.fromJSDate(endDate).set({hour: 23, minute: 59, second: 59, millisecond: 999});

    let className = 'selected-day';
    const start = DateTime.fromJSDate(startDate).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    className += day.hasSame(start, 'day') ? ' start-day' : '';
    className += day.hasSame(fixedEndDate, 'day') ? ' end-day' : '';
    const isBetween = Interval.fromDateTimes(start, fixedEndDate).contains(day);

    if (dayInCurrentMonth && isBetween) {
      return (
        <div key={key} className={className}>
          {dayComponent}
        </div>
      );
    }

    return (
      <div key={props.key} className={'regular-day'}>
        {dayComponent}
      </div>
    );
  };

  /**
   * Handles clear dates button click.
   *
   * Sets start and end dates to the range prop value and resets the
   * frontier and calendar selected dates.
   */
  const handleClear = () => {
    setStartDate(shortcuts[2].startDate);
    setEndDate(shortcuts[2].endDate);

    const f = calculateFrontier(shortcuts[2]);
    setFrontier(f);
    refreshCalendars(shortcuts[2], f, rightCalDate);
  };

  /**
   * Handles the select dates button click and triggers the onChange event.
   */
  const handleSelected = () => {
    onChange({startDate, endDate});
    setSelected(true);
    setAnchorEl(null);
  };

  /**
   * Handles shortcut button click event.
   *
   * Sets the start and end dates to the shortcut values and resets
   * the frontier and calendar selected dates.
   *
   * @param startDate
   * @param endDate
   */
  const applyShortcut = ({startDate, endDate}) => {
    setFirst(true);
    setStartDate(startDate);
    setEndDate(endDate);
    const range = {startDate, endDate};
    const f = calculateFrontier(range);
    setFrontier(f);
    refreshCalendars(range, f, rightCalDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <StyledDateRangePicker>
        <Box display={'flex'} maxHeight={size === 'small' ? '40px' : 'inherit'}>
          <Button
            size={'small'}
            disabled={disabled}
            variant={'outlined'}
            color={'secondary'}
            sx={StyledButton.leftArrow}
            onClick={() => {
              const previous = getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}, true) - 1 > -1 ? getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}, true) - 1 : shortcuts.length - 1;
              getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}) ?
                applyShortcut(shortcuts[previous]) : applyShortcut(shortcuts[0]);
              getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}) ?
                onChange(shortcuts[previous]) : onChange(shortcuts[0]);
            }
          }
          >
            <KeyboardArrowLeft sx={{fontSize: '180%', color: 'text.secondary'}} />
          </Button>
        <Button
          size={'small'}
          fullWidth
          disabled={disabled}
          color={'secondary'}
          variant={'outlined'}
          sx={StyledButton.main(open, size)}
          onClick={handleClick}
        >
          <Box display={'flex'} alignItems={'center'}>
            <div>
              <CalendarToday sx={{fontSize: '160%', pt: '6px', pr: '10px', color: open ? 'secondary.main' : 'text.secondary'}} />
            </div>
            {getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}) ?
              <Box display={'flex'} height={'37.5px'} alignItems={'center'}>
                <Typography color={open ? 'secondary.main' : 'text.secondary'} fontFamily={'NeoSansProMedium'} fontSize={'0.875rem'}>{getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}).label}</Typography>
              </Box>
              :
              <Box fontFamily={'NeoSansProMedium'} color={open ? 'secondary.main' : 'text.secondary'} pt={0.45} lineHeight={1.3045}>
                <div>{DateTime.fromJSDate(startDate).setLocale(localStorage.getItem('LANG')).toFormat('dd LLL yyyy')}</div>
                <div>{DateTime.fromJSDate(endDate).setLocale(localStorage.getItem('LANG')).toFormat('dd LLL yyyy')}</div>
              </Box>
            }
          </Box>
        </Button>
          <Button
            size={'small'}
            disabled={disabled}
            variant={'outlined'}
            color={'secondary'}
            sx={StyledButton.rightArrow}
            onClick={() => {
              const next = getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}, true) + 1 <= (shortcuts.length - 1) ? getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}, true) + 1 : 0;
              getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}) ?
                applyShortcut(shortcuts[next]) : applyShortcut(shortcuts.at(-1))
              getCurrentRangeIsShortcut({startDate: startDate, endDate: endDate}) ?
                onChange(shortcuts[next]) : onChange(shortcuts.at(-1))
            }}
          >
            <KeyboardArrowRight sx={{fontSize: '180%', color: 'text.secondary'}} />
          </Button>
        </Box>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            style: {width: !useMediaQuery('(max-width: 768px)') && '820px'}
          }}
        >
          <StyledDatePickerWrapper className={'date-picker-wrapper'}>
            <Box maxHeight={'30px'} minWidth={'160px'} pb={1}>
              <Tabs
                value={false}
                variant={'scrollable'}
                ScrollButtonComponent={(props) => (
                  <TabScrollButton
                    {...props}
                    sx={{width: '30px', height: '38px', '&.Mui-disabled': {display: 'none'}}}
                  />
                )}
                orientation={useMediaQuery('(max-width: 768px)') ? 'horizontal' : 'vertical'}
                scrollButtons={'auto'}
                allowScrollButtonsMobile={true}
                sx={{pt: useMediaQuery('(max-width: 768px)') ? 1 : 3.5}}
              >
                {shortcuts.map((s, i) => (
                  <Tab
                    key={i}
                    onClick={() => {
                      applyShortcut(s);
                    }}
                    label={s.label}
                    sx={{color: 'secondary.main', minHeight: '10px !important', py: 1.5}}
                  />
                ))}
              </Tabs>
            </Box>
            <Box className={'date-picker-calendar-wrapper'}>
              <Box display={'flex'} className={'date-calendars-selector'}>
                <Box className={'picker-wrapper start-calendar'}>
                  <CalendarPicker
                    autoOk
                    disableToolbar
                    value={DateTime.fromJSDate(leftCalDate)}
                    date={DateTime.fromJSDate(frontier).startOf('month')}
                    onChange={handleDateSelected}
                    openTo='day'
                    disableFuture={disableFuture}
                    renderDay={handleRenderDay}
                    maxDate={DateTime.fromJSDate(frontier).endOf('month')}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{width: '270px !important'}}
                  />
                </Box>
                <Box className={'picker-wrapper end-calendar'}>
                  <CalendarPicker
                    disableToolbar
                    value={DateTime.fromJSDate(rightCalDate)}
                    date={DateTime.fromJSDate(rightCalDate).startOf('month').plus({day: 1})}
                    onChange={handleDateSelected}
                    disableFuture={disableFuture}
                    openTo={'day'}
                    renderDay={handleRenderDay}
                    minDate={DateTime.fromJSDate(frontier).startOf('month').plus({day: 1})}
                  />
                </Box>
              </Box>
            </Box>
          </StyledDatePickerWrapper>
          <Divider
            sx={{
              marginTop: useMediaQuery('(max-width: 768px)') ? '23px' : '50px'
            }}
          />
          <Box
            sx={{
              width: 'calc(100% - 24px)',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px'
            }}
          >
            <Button
              size={'small'}
              variant={'outlined'}
              color={'primary'}
              onClick={handleClear}
            >{t`Reset dates`}</Button>
            <Button
              size={'small'}
              variant={'contained'}
              color={'secondary'}
              onClick={handleSelected}
            >{t`Select dates`}</Button>
          </Box>
        </Popover>
      </StyledDateRangePicker>
    </LocalizationProvider>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }),
  disabled: PropTypes.bool
};


export default DateRangePicker;
