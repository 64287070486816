import {useCheckResponsibilityTermQuery, useUpdateResponsibilityTermMutation} from '../../store/apis/ccs';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import {t} from 'ttag';
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {Check} from "@mui/icons-material";

export const messageTemplateKey = 'USER_NAME_INPUT';
export const messageTemplate = t`Eu, titular do username ${messageTemplateKey} assumo o compromisso de confidencialidade sobre todos os dados pessoais (NIF) e informação de residência a que tenha acesso na validação de condições de elegibilidade no âmbito da Tarifa Açores, e que o acesso aplicacional só se destina a este fim, bem como declaro que a informação reportada só será usada com esta finalidade.`;

export const ResidentValidatorTermsGuard = ({children = null}: {children: any}) => {
  const navigate = useNavigate();
  const {data: isAccept, isLoading, error} = useCheckResponsibilityTermQuery(undefined);
  const [update, updateResponse] = useUpdateResponsibilityTermMutation();
  const [providedUsername, setProvidedUsername] = useState('');
  const [providedUsernameError, setProvidedUsernameError] = useState(false);

  if (error) {
    enqueueSnackbar(t`The resident validator is temporarily unavailable. Please try again later.`, {variant: 'error', preventDuplicate: true});
    navigate('/');
  }

  if (isAccept) return children;

  const onAccept = () => update({message: messageTemplate.replace(messageTemplateKey, providedUsername), providedUsername, isAccept: true}).then((r: any) => {
    if (!r?.error) {
      enqueueSnackbar(t`You have accepted our terms and conditions!`, {variant: 'success'});
    }
  });

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Dialog maxWidth={'sm'} onClose={() => navigate(-1)} open={isAccept === undefined ? false : !isAccept}>
        <DialogTitle>
          <Typography sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Terms and Conditions`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{lineHeight: '1.4375em', fontSize: '1rem', fontFamily: 'NeoSansProRegular'}}>
          {t`I, the holder of the username `}
          <TextField
            placeholder={t`Insert username`}
            variant={'standard'}
            size={'small'}
            value={providedUsername}
            error={providedUsernameError}
            required
            onBlur={() => setProvidedUsernameError(!providedUsername)}
            onChange={(e) => {
              setProvidedUsername(e.target.value);
              setProvidedUsernameError(!e.target.value);
            }}
            inputProps={{style: {padding: '0px 0px 0px'}}}
          />
          {t` assume the commitment of confidentiality on all personal data (NIF) and residence information to which it has access in the validation of eligibility conditions under the Azores Fare, and that the application access is only intended for this purpose, as well as declare that the reported information will only be used for this purpose.`}
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button
              variant={'contained'}
              disabled={!providedUsername || updateResponse.isSuccess || updateResponse.isLoading}
              onClick={onAccept}
              startIcon={<Check/>}
            >
              {t`Accept`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback isLoading={isLoading}/>
      <RTKQueryFeedback error={updateResponse?.error as any}/>
    </Box>
  );
};

export default ResidentValidatorTermsGuard;
