// @ts-nocheck
import React from 'react';
import { t } from 'ttag';
import {Box, Button} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useCreateRefundMutation } from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';
import { useSnapshot } from 'valtio';
import formState, {
  decStep,
  incStep,
  resetToDefaultState,
  setFormData
} from '../store/form-state';
import {useNavigate} from "react-router-dom";
import {Add, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

const FormStepperActions = () => {
  const navigate = useNavigate();
  const { step } = useSnapshot(formState);
  const [createRefund, { data, error, isLoading }] = useCreateRefundMutation();
  const { handleSubmit } = useFormContext();
  const isLastStep = step === 2;

  const localHandleSubmit = (values) => {
    if (!isLastStep) {
      // Populate recipient
      if (step === 0) {
        setFormData(values);
        incStep();
      }
      if (step === 1) {
        setFormData(values);
        incStep();
      }

    } else {
      createRefund({ ...values, officeCode: values.office?.officeCode }).then(
        (r) => {
          if (!r.error) {
            resetToDefaultState();
            enqueueSnackbar(t`Refund` + ` ${r.data.processId} ` + t`successfully created!`, { variant: 'success' });
            navigate(`/refunds/${r.data.refundId}`);
          }
        }
      );
    }
  };

  const prevHandler = () => decStep();
  const nextHandler = () => handleSubmit(localHandleSubmit)();

  return (
    <Box textAlign={'right'} justifyContent={'flex-end'} display={'flex'} gap={2}>
      {step > 0 && (
        <Button
          onClick={prevHandler}
          variant={'outlined'}
          startIcon={<KeyboardArrowLeft />}
        >
          {t`Back`}
        </Button>
      )}
      <Button
        onClick={nextHandler}
        endIcon={isLastStep ? <Add /> : <KeyboardArrowRight />}
        variant={'contained'}
      >
        {isLastStep ? t`Create` : t`Next`}
      </Button>
      <RTKQueryFeedback error={error} isLoading={isLoading} />
    </Box>
  );
};

export default FormStepperActions;
