// @ts-nocheck
import React from 'react';
import {columns} from './refundSubjectsListColumns';
import PropTypes from 'prop-types';
import {setRefundSubjectToDelete, setRefundSubjectToEdit} from '../../../common/store/form-state';
import {DataGrid} from "@mui/x-data-grid";
import table from "../../../../../../../components/common/styles";

const RefundSubjectsList = ({refundSubjects = [], isLoading = false, editable = true}) => {
  return (
    <DataGrid
      hideFooter
      density={'compact'}
      autoHeight
      getRowId={({documentNumber}) => documentNumber}
      onRowDoubleClick={({row}) => setRefundSubjectToEdit(row)}
      columns={columns({
        viewHandler: setRefundSubjectToEdit,
        deleteHandler: setRefundSubjectToDelete,
        editable
      })}
      rows={refundSubjects}
      loading={isLoading}
      disableRowSelectionOnClick
      sx={table}
    />
  );
};

RefundSubjectsList.propTypes = {
  refundSubjects: PropTypes.array,
  isLoading: PropTypes.bool,
  editable: PropTypes.bool,
};

export default RefundSubjectsList;
