// @ts-nocheck
import { t } from 'ttag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UserSearch from '../../../../components/user-search';
import { useAddUserMutation } from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";

const ImportEmployeeDialog = ({ open, onClose }) => {
  const [addUser, { data, error, isLoading }] = useAddUserMutation();
  const [employeeId, setEmployeeId] = useState(null);

  const handleSave = () => {
    addUser({
      employeeId: employeeId
    }).then((r) => {
    if (!r.error) {
      enqueueSnackbar(t`The employee` + ` ${r?.data?.employeeId} ` + t`was successfully imported!`, {variant: 'success'});
      onClose();
    }});
  };

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
      />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'md'}
        fullWidth
      >
        <DialogTitle>
          <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
            {t`Import Employee`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <UserSearch label={t`Employee to import`} onSelectUser={setEmployeeId} />
        </DialogContent>
        <DialogActions>
          <Box mx={1}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box mx={1}>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={isLoading}
            >
              {t`Import`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

ImportEmployeeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ImportEmployeeDialog.defaultProps = {
  open: true
};

export default ImportEmployeeDialog;
