import {t} from "ttag";
import {Theme} from "@mui/material";
import React, {useEffect} from 'react';
import {Home} from '@mui/icons-material';
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useNavigate} from 'react-router-dom';
import {Box, Button, Typography} from '@mui/material';
import logo from '../../../assets/sata-logo.png';

const Logout = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      const itemKey = "accessToken";
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: (_) => {
          if (localStorage.getItem(itemKey)) {
            localStorage.removeItem(itemKey);
          }
          return false;
        }
      }).then(() => {
      });
    }
  }, [ instance, isAuthenticated, navigate ]);

  return (
      <Box sx={classes.root}>
        {account ? (
            <Typography align='center' variant='h2'>{t`Clearing your session, please wait...`}</Typography>
        ) : (
            <Typography align='center' variant='h2'>{t`Thank you. See you soon!`}</Typography>
        )}
        <Box mt={10} display='flex' justifyContent='center'>
          <Box
              component={'img'}
              alt='under development'
              sx={classes.image}
              src={`/static/images/undraw_close_tab_uk6g.svg`}
          />
        </Box>
        <Box mt={2} display='flex' justifyContent='flex-end' width='24rem'>
          <Box component={'img'} sx={classes.logo} alt='SATA' src={logo} />
        </Box>

        {!account && (
            <Box mt={4} display='flex' justifyContent='center'>
              <Button sx={{alignItems: 'normal'}} startIcon={<Home />} color='secondary' onClick={() => {
                instance.setActiveAccount(null);
                instance.clearCache();
                window.sessionStorage.clear();
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                window.location.href = '/';
              }} variant='outlined'>
                {`Go to home page`}
              </Button>
            </Box>
        )}
      </Box>
  );
};

const classes = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: (theme: Theme) => theme.spacing(3),
    paddingTop: (theme: Theme) => theme.spacing(10)
  },
  caption: {
    justifyContent: 'flex-start',
    marginTop: (theme: Theme) => theme.spacing(1),
    display: 'flex',
    width: '20rem'
  },
  image: {
    maxWidth: '100%',
    width: '35rem',
    maxHeight: '16.75rem',
    height: 'auto'
  },
  logo: {
    width: '9.375rem'
  }
};

export default Logout;

