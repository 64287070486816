// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {Skeleton, Typography} from '@mui/material';

const LabeledDetail = ({ label, children, isLoading }) => {
  return (
    <>
      <Typography variant='h5' component='h2' fontFamily={'NeoSansProMedium'}>
        {label}
      </Typography>
      {!isLoading && (
        <Typography color='textSecondary'>
          {children || '-'}&nbsp;
        </Typography>
      )}
      {isLoading && <Skeleton width='60%' />}
    </>
  );
};

LabeledDetail.propTypes = {
  label: PropTypes.string,
  children: PropTypes.string,
  isLoading: PropTypes.bool
};

LabeledDetail.defaultProps = {
  isLoading: false
};

export default LabeledDetail;
