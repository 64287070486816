import {t} from "ttag";
import React from 'react';
import {Check} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useRefundAuthorizationMutation} from "../../../../api";
import {IAuthorization} from "../../../../api/transportFacilityEndpoints.types";
import RTKQueryFeedback from "../../../../../components/common/RTKQueryFeedback";
import {enqueueSnackbar} from 'notistack';

export const TransportFacilitiesRefund = ({data, onClose, fullScreen}: {data: any, onClose: () => void, fullScreen: boolean}) => {
  const [onRefund, {data: dataRefund, error, isLoading}] = useRefundAuthorizationMutation();

  const handleSave = () => {
    onRefund(data).then((e: {data?: IAuthorization; error?: any}) => {
      if (!e?.error) {
        onClose();
        enqueueSnackbar(t`Transport Facility refunded successfully!`, {variant: 'success'});
      }
    });
  };

  return (
    <>
    <Dialog fullScreen={fullScreen} onClose={onClose} open>
      <DialogTitle color={'secondary'} variant={'h4'}>{'Refund Transport Facility'}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{t`Do you really want to refund this Transport Facility?`}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'space-between'}}>
        <Button variant={'outlined'} color={'inherit'} onClick={onClose} autoFocus>
          {t`Cancel`}
        </Button>
        <Button
          startIcon={<Check />}
          onClick={handleSave}
          disabled={isLoading}
          variant={'contained'}
          color={'primary'}
          autoFocus
        >{t`Confirm`}</Button>
      </DialogActions>
    </Dialog>
    <RTKQueryFeedback data={dataRefund} error={error} isLoading={isLoading} successMessage={t`Transport Facility refunded with success!`} />
    </>
  );
};

export default TransportFacilitiesRefund;
