import {Avatar} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {axiosAzureInstance} from "../../../utils/auth-axios-instance";
import {Employee} from "../../api/userEndpoints.types";

interface ProfilePictureProps {
  userData?: Employee;
  height: string;
  width: string;
}

const ProfilePicture = ({userData, height, width}: ProfilePictureProps) => {
  const [photo, setPhoto] = useState('');
  useEffect(() => {
    if (userData) {
      axiosAzureInstance
        .get(`https://graph.microsoft.com/v1.0/users/${userData?.email}/photo/$value`, {
          responseType: 'blob'
        })
        .then((data) => {
          if (data?.status === 200) {
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(data.data);
            setPhoto(blobUrl);
          }
        }).catch((e) => {console.error(e)});
    }
  }, [userData]);

  const initials = (fullName: string) =>
  {
    let arrName = fullName.split(" ");
    return fullName.charAt(0) + arrName[arrName.length - 1].charAt(0);
  }


  return photo ? (
    <Avatar sx={{height, width}} src={photo} />
  ) : (
    <Avatar sx={{height, width}}>
      {initials(userData?.name || '-')}
    </Avatar>
  );
};

export default ProfilePicture;
