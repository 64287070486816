// @ts-nocheck
import React, {useState} from 'react';
import {columns} from './issueOfficesListHelper';
import {useGetIssueOfficesQuery} from '../../../store/apis/ccs';
import PropTypes from 'prop-types';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {DataGrid} from "@mui/x-data-grid";
import table from "../../../components/common/styles";

const IssueOfficesList = ({editHandler, removeHandler}) => {
  const [page, setPage] = useState(1);
  const {data = [], error, isFetching} = useGetIssueOfficesQuery();

  return (
    <>
      <RTKQueryFeedback error={error}/>
      <DataGrid
        disableColumnSelector
        disableRowSelectionOnClick
        disableDensitySelector
        pagination
        paginationModel={{page: page - 1, pageSize: data?.pagination?.rowsPerPage || 12}}
        rowCount={data?.count || 0}
        onPaginationModelChange={(pagination) => {
          setPage(pagination.page + 1);
        }}
        autoHeight
        getRowId={({issueOfficeId}) => issueOfficeId}
        columns={columns({editHandler, removeHandler})}
        rows={data || []}
        pageSize={100}
        rowsPerPageOptions={[100]}
        loading={isFetching}
        disableSelectionOnClick
        sx={table}
      />
    </>

  );
};

IssueOfficesList.propTypes = {
  editHandler: PropTypes.func.isRequired,
  removeHandler: PropTypes.func.isRequired,
};

export default IssueOfficesList;
