import React from 'react';
import {Button, ButtonGroup} from '@mui/material';
import {SubjectOutlined, TableChartOutlined} from '@mui/icons-material';

const AtcMaskToggle = ({onChange, visible}: {onChange: (e: boolean) => void, visible: boolean}) => {

  return (
    <ButtonGroup variant={'contained'} size={'small'} color={'primary'}>
      <Button onClick={() => onChange(false)} color={!visible ? 'primary': 'inherit'}><TableChartOutlined fontSize={'small'}/></Button>
      <Button onClick={() => onChange(true)} color={visible ? 'primary': 'inherit'}><SubjectOutlined fontSize={'small'}/></Button>
    </ButtonGroup>
  );
};

export default AtcMaskToggle;
