import React from 'react';
import {ListItem, ListItemAvatar, ListItemText} from '@mui/material';
import {Skeleton} from "@mui/material";

const HistorySkeleton = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circular' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Skeleton />
          </>
        }
        secondary={
          <React.Fragment>
            <Skeleton />
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

HistorySkeleton.propTypes = {
};

HistorySkeleton.defaultProps = {
  avatar: 'https://i.imgur.com/mCHMpLT.png'
};

export default HistorySkeleton;
