import * as Yup from 'yup';
import {t} from 'ttag';

export const validationSchema = Yup.object().shape({
  reservationCode: Yup.string()
    .nullable()
    .required(t`Required field.`),
  currencyCode: Yup.string()
    .nullable()
    .required(t`Required field.`)
    .matches(/^[A-Z]{3}$/, t`Currency should be a valid one. (ex. EUR)`),
  companyCode: Yup.string()
    .nullable()
    .required(t`Required field.`),
  documentNumber: Yup.string()
    .nullable()
    .required(t`Required field.`),
  atcMask: Yup.string()
    .nullable()
    .required(t`Required field.`),
  issueDate: Yup.string()
    .nullable(),
  modified: Yup.boolean()
    .required(t`Required field`),
  coupons: Yup.array().of(Yup.object().shape({
    originAirport: Yup.string().nullable()/*.required(t`Required field.`) and added nullable*/,
    destinationAirport: Yup.string().nullable()/*.required(t`Required field.`) and added nullable*/,
    carrier: Yup.string().nullable(),
    flightNumber: Yup.string().nullable(),
    fareBasis: Yup.string().nullable(),
    couponStatus: Yup.string().nullable(),
    datetime: Yup.string().nullable(),
  })).min(1, t`At least one coupon is required`),
  amounts: Yup.array().of(Yup.object().shape({
    type: Yup.string().required(t`Required field.`),
    value: Yup.number().required(t`Required field.`),
    currentValue: Yup.number().min(0, "Must be a positive number").required(t`Required field.`),
    currency: Yup.string().nullable(),
    modified: Yup.boolean().nullable(),
  })).min(1, t`At least one amount is required`),
  taxes: Yup.array().of(Yup.object().shape({
    taxCode: Yup.string().required(t`Required field`),
    type: Yup.string().required(t`Required field`),
    value: Yup.number().required(t`Required field`),
    currentValue: Yup.number().min(0, "Must be a positive number").required(t`Required field`),
    active: Yup.boolean().nullable(),
    currency: Yup.string().nullable(),
    modified: Yup.boolean().nullable(),
  }))/*.compact(tx => tx.type !== 'refundableTaxes').min(1, t`At least one refundable tax is required`)*/,
  paymentOptions: Yup.array().of(Yup.object().shape({
    indicator: Yup.string().required(t`Required field`),
    value: Yup.string().required(t`Required field`),
    currentValue: Yup.number().min(0, "Must be a positive number").required(t`Required field`),
    active: Yup.boolean().nullable(),
    modified: Yup.boolean().nullable(),
  })).min(1, t`At least one payment option is required`),
  totalRefund: Yup.number().when('paymentOptions', (paymentOptions, schema) => {
    return schema.test({
      test: totalRefund => totalRefund === paymentOptions?.flat()?.filter(p => p.active)?.reduce((sum, p) => sum + p.currentValue, 0),
      message: t`The payment options total should be equal to refund subject total`
  })}),
});
