import React from 'react';
import {TextField} from '@mui/material';

interface ICurrentValueTextFieldProps {
  params: {
    value: number,
    id: string,
    field: string,
    api: {
      setEditCellValue: (params: {id: string, field: string, value: any}) => void
    },
    row: any
  },
  editHandler: (params: any) => void
}

export const CurrentValueTextField = ({params, editHandler}: ICurrentValueTextFieldProps) => {
  return (
    <TextField
      variant='standard'
      size={'small'}
      value={params.value}
      type={'number'}
      inputProps={{min: 0, step: 'any'}}
      InputProps={{
        inputMode: 'numeric',
        endAdornment: <p style={{paddingTop: '1px'}}>{params?.row?.currency}</p>,
        disableUnderline: true,
        style: {
          fontSize: '0.875rem',
          color: 'rgba(0, 85, 138, 1)'
        }
      }}
      sx={{
        '& .MuiInputBase-input': {
          pt: 0.7,
          textAlign: 'right',
          pr: 0.25
        },
        pr: 1.25,
      }}
      autoFocus
      fullWidth
      onChange={(e) => {
        const updatedValue = e.target.value.length === 0 ? '' : Number(Number(e.target.value) > 0 ? e.target.value : 0);
        params.api.setEditCellValue({
          id: params.id,
          field: params.field,
          value: updatedValue.toString().length === 0 ? '' : Number(updatedValue).toFixed(2)
        });
        editHandler({...params, value: updatedValue || 0});
      }}
    />
  )
}

export default CurrentValueTextField;
