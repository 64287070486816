// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {t} from 'ttag';
import RefundSubjectForm from './RefundSubjectForm';
import AtcMaskToggleConnected from './form/views/atc-mask/AtcMaskToggleConnected';
import {useFormContext} from 'react-hook-form';
import ErrorsFeedbackConnected from '../../../../common/ErrorsFeedbackConnected';
import RefundSubjectTotal from './form/views/RefundSubjectTotal';

const RefundSubjectFormDialog = ({ onSubmit, open, onClose, actionTitle, editable, ...rest }) => {

  const {handleSubmit} = useFormContext();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'lg'} {...rest}>
      <DialogTitle>
        <Box display={'flex'} justifyContent={'space-between'}>
          <div>
            <Typography variant='h2' sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>
              {t`Refund subject`}
            </Typography>
            <RefundSubjectTotal/>
          </div>
          <AtcMaskToggleConnected/>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={1}>
          <RefundSubjectForm editable={editable}/>
        </Box>
        <Box p={1}>
          <ErrorsFeedbackConnected/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={0.4}>
          <Button variant={'outlined'} onClick={onClose}>{editable ? t`Cancel` : t`Close`}</Button>
        </Box>
        {editable && (
          <Box p={1} px={0.4}>
            <Button variant={'contained'} onClick={handleSubmit(onSubmit)}>
              {actionTitle}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

RefundSubjectFormDialog.propTypes = {
  actionTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  editable: PropTypes.bool,
  onClose: PropTypes.func
};

RefundSubjectFormDialog.defaultProps = {
  actionTitle: 'Save',
  onSubmit: () => null,
  open: true,
  editable: true,
  onClose: null
};

export default RefundSubjectFormDialog;
