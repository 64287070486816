// @ts-nocheck
import React, {useMemo} from 'react';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {useWatch} from 'react-hook-form';
import Amount from './Amount';
import {DataGrid} from '@mui/x-data-grid';
import table from '../../../../../../../../../../components/common/styles';

const columns = [
  {
    field: 'pnr',
    headerName: t`PNR`,
    width: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true
  },
  {
    field: 'ticket',
    headerName: t`Ticket`,
    width: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'secondary-text'
  },
  {
    field: 'fare',
    headerName: t`Fare`,
    width: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({value}) => <Amount {...value} />,
    cellClassName: 'bold'
  },
  {
    field: 'total_taxes',
    headerName: t`Total Taxes`,
    width: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({value}) => <Amount {...value} />,
    cellClassName: 'bold'
  },
  {
    field: 'total_amount',
    headerName: t`Total Amount`,
    width: 150,
    flex: 1,
    renderCell: ({value}) => <Amount {...value} />,
    cellClassName: 'bold'
  }
];

const RefundSubjectInfo = () => {
  const [reservationCode, companyCode, documentNumber, amounts] = useWatch({
    name: ['reservationCode', 'companyCode', 'documentNumber', 'amounts']
  });

  const totalFareRefund = useMemo(() => amounts?.find((a) => a.type === 'totalFareRefund') || {}, [amounts]);
  const totalTaxes = useMemo(() => amounts?.find((a) => a.type === 'totalPaidTaxes') || {}, [amounts]);
  const totalAmount = useMemo(() => amounts?.find((a) => a.type === 'totalAmount') || {}, [amounts]);

  return (
    <Card elevation={3}>
      <CardHeader title={<Typography fontFamily={'NeoSansProMedium'}>{t`Basic info`}</Typography>} />
      <Divider/>
      <CardContent>
        <DataGrid
          columns={columns}
          rows={[
            {
              id: 1,
              pnr: reservationCode,
              ticket: `${companyCode} ${documentNumber}`,
              fare: totalFareRefund,
              total_taxes: totalTaxes,
              total_amount: totalAmount
            }
          ]}
          density={'compact'}
          disableRowSelectionOnClick
          hideFooter
          sx={table}
        />
      </CardContent>
    </Card>
  );
};

RefundSubjectInfo.propTypes = {
  formik: PropTypes.shape({})
};

export default RefundSubjectInfo;
