import React from 'react';
import {Chip, Grid, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {t} from 'ttag';
import LabeledRowField from '../labeled-row-field/LabeledRowField';
import ProfilePicture from '../profile-picture/ProfilePicture';
import {IPersonalInformation} from './personal-information.types';
import {useGetCurrentSelectedUserQuery} from "../../api";
import {createDatetime} from "../../../utils/datetime/datetime-utils";

export const PersonalInformation = ({
  employeeId
}: IPersonalInformation) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const {data: userData, isFetching} = useGetCurrentSelectedUserQuery({employeeId});

  return (
    <Grid spacing={2} container>
      <Grid xs={12} xl={2} item>
        <Stack gap={2}>
          {!isMobile && <ProfilePicture width={!isMobile ? '110px' : '110px'} height={!isMobile ? '110px' : '110px'} userData={userData} />}
        </Stack>
      </Grid>
      <Grid xs={12} xl={5} item>
        <Stack gap={2}>
          <Typography sx={{fontFamily: 'NeoSansProBold', color: '#0082BB', fontSize: '1.125rem'}}>
            {userData?.name}
          </Typography>
          <LabeledRowField label={t`EMPLOYEE Nº`} isLoading={isFetching}>
            <Chip
              size={'small'}
              sx={{bgcolor: '#90CAF933'}}
              label={
                <Typography sx={{color: '#67AEE8', fontSize: '0.85rem', lineHeight: '1rem'}}>{employeeId}</Typography>
              }
            />
          </LabeledRowField>
          <LabeledRowField label={t`OCCUPATION`} isLoading={isFetching} text={userData?.jobTitle} />
        </Stack>
      </Grid>
      <Grid xs={12} xl={5} item>
        <Stack gap={2}>
          <LabeledRowField label={t`COMPANY`} isLoading={isFetching} text={userData?.company?.name} />
          <LabeledRowField label={t`CATEGORY`} isLoading={isFetching} text={userData?.category} />
          <LabeledRowField label={t`CONTRACT START DATE`} isLoading={isFetching} text={`${userData?.hiredSince && createDatetime(userData?.hiredSince)?.toFormat('dd MMMM, yyyy')} - ${userData?.workingDays} working days`} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export const styles = {
  dialogTitle: {
    fontSize: '1.375rem',
    color: '#002857',
    fontFamily: 'NeoSansProMedium'
  },
  dialogSubTitle: {
    fontSize: '1rem',
    color: '#A7B5C5'
  },
  infoTitle: {
    fontFamily: 'NeoSansProBold',
    fontSize: '1.25rem',
    color: '#101828'
  },
  table: {
    border: '0px',
    '& .MuiDataGrid-columnHeaders': {
      borderTop: '1px solid #efefef',
      borderBottom: '1px solid #efefef',
      bgcolor: '#F9FAFB',
      fontFamily: 'neoSansProBold',
      color: '#00285780',
      fontSize: '0.75rem',
      height: '55px',
      minHeight: '55px !important',
      maxHeight: '55px !important'
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    }
  }
};

export default PersonalInformation;
