import {t} from 'ttag';
import React, {useEffect, useState} from 'react';
import {CardHeader, Divider, Paper, Typography} from '@mui/material';
import CreateRefundFormProvider from './common/CreateRefundFormProvider';
import {resetToDefaultState} from './common/store/form-state';
import FormStepper from './common/stepper/FormStepper';
import TotalRefund from './common/views/TotalRefund';

const CreateRefundPage = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    resetToDefaultState();
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }
  return (
    <CreateRefundFormProvider>
      <Paper elevation={3} sx={{p: 2}}>
        <CardHeader
          sx={{p: 0, pr: 1}}
          title={<Typography sx={{fontFamily: 'NeoSansProMedium', fontSize: '1.375rem'}}>{t`New Refund`}</Typography>}
          subheader={
            <Typography>
              <TotalRefund/>
            </Typography>
          }
        />
        <Divider sx={{mx: -2, my: 2}}/>
        <FormStepper/>
      </Paper>
    </CreateRefundFormProvider>
  );
};

export default CreateRefundPage;
