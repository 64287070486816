// @ts-nocheck
import React, {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './refundSubjectRefundableTaxesColumns';
import ConfirmDialog from '../../../../../../../../../../../components/common/ConfirmDialog';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Divider, IconButton, Typography} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useFormContext, useWatch} from 'react-hook-form';
import table from '../../../../../../../../../../../components/common/styles';

export const EMPTY_TAX = {localId: -1, type: 'refundableTaxes', taxCode: 'XX', value: 0, currentValue: 0, active: true, modified: true};

const RefundSubjectRefundableTaxes = ({editable}) => {

  const {setValue} = useFormContext();
  const [currencyCode, taxes, taxesOriginal] = useWatch({name: ['currencyCode', 'taxes', 'original.taxes']});
  const [taxToDelete, setTaxToDelete] = useState(null);

  const createHandler = () => {
    const newTax = {...EMPTY_TAX};
    newTax.localId = taxes?.length || -1;
    newTax.currency = currencyCode;
    setValue('taxes', taxes.concat([newTax]), {shouldValidate: true});
  }

  const editHandler = ({field, value, id}) => {
    const original = taxesOriginal.find(({localId}) => localId === id);
    const modified = Boolean(!original || original?.[field] !== value);
    setValue('taxes', taxes.map(tx => tx.localId === id ? {...tx, [field]: value, modified} : tx), {shouldValidate: true});
  }

  const removeHandler = () => {
    const {localId: id} = taxToDelete;
    const original = taxesOriginal.find(({localId}) => localId === id);
    if (original) {
      setValue('taxes', taxes.map(tx => tx.localId === id ? {...tx, active: false, modified: true} : tx), {shouldValidate: true});
    } else {
      setValue('taxes', taxes.filter(tx => tx.localId !== id), {shouldValidate: true});
    }
    setTaxToDelete(null);
  }

  const AddRowBtn = () => (
    <IconButton onClick={createHandler} size={'small'}>
      <PlaylistAddIcon/>
    </IconButton>
  );

  return (
    <Card elevation={3}>
      <CardHeader title={<Typography fontFamily={'NeoSansProMedium'}>{t`Refundable taxes`}</Typography>} action={editable && <AddRowBtn/>}/>
      <Divider/>
      <CardContent>
        <DataGrid
          autoHeight
          density={'compact'}
          onCellEditCommit={editHandler}
          getRowId={({localId}) => localId}
          columns={columns({
            removeHandler: setTaxToDelete,
            editHandler,
            editable,
            disableDeleteBtn: !editable || taxes?.filter(({type, active}) => type === 'refundableTaxes' && active)?.length <= 1,
          })}
          rows={taxes?.filter(({type, active}) => type === 'refundableTaxes' && active) || []}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableRowSelectionOnClick
          hideFooter
          sx={table}
        />
        {Boolean(taxToDelete) && (
          <ConfirmDialog
            title={t`Tax`}
            children={t`Do you really want to remove this tax?`}
            cancelText={t`Cancel`}
            confirmText={t`Delete`}
            variant={'error'}
            onConfirm={removeHandler}
            onCancel={() => setTaxToDelete(null)}
            reverse
          />
        )}
      </CardContent>
    </Card>
  );
};

RefundSubjectRefundableTaxes.propTypes = {
  editable: PropTypes.bool,
};

RefundSubjectRefundableTaxes.defaultProps = {
  editable: true,
};

export default RefundSubjectRefundableTaxes;
